@font-face {
    font-family: "Roboto-Light, sans-serif";
    src: local("Roboto-Light"), url(../fonts/Roboto/Roboto-Light.ttf) format("truetype");
}


@font-face {
    font-family: "Roboto";
    src: local("Roboto-Medium"), url(../fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}


@font-face {
    font-family: 'Roboto';
    src: local("Roboto-Regular"), url(../fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}


@font-face {
    font-family: 'Roboto', sans-serif;
    src: local("Roboto-Bold"), url(../fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Celias";
    src: local("Celias"), url(../fonts/Celias/Celias.ttf) format("truetype");
}

@font-face {
    font-family: "Celias-Light";
    src: local("Celias-Light"), url(../fonts/Celias/Celias-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: local("Montserrat-Medium"),
        url(../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}


@font-face {
    font-family: 'Poppins-bold';
    src: local("Poppins-bold"), url(../fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: 'Poppins-semi-bold';
    src: local("Poppins-semi-bold"), url(../fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins-Medium"), url(../fonts/Poppins-Medium.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins-regular';
    src: local("Poppins-regular"), url(../fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
    font-family: 'Poppins-light';
    src: local("Poppins-light"), url(../fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Noto Sans";
    src: local("NotoSans-Regular"), url(../fonts/Noto_Sans/NotoSans-Regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Noto Sans";
    src: local("NotoSans-Medium"), url(../fonts/Noto_Sans/NotoSans-Medium.ttf) format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Noto Sans";
    src: local("NotoSans-Light"), url(../fonts/Noto_Sans/NotoSans-Light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Noto Sans";
    src: local("NotoSans-SemiBold"), url(../fonts/Noto_Sans/NotoSans-SemiBold.ttf) format("truetype");
    font-weight: 600;
}

/* @font-face {
    font-family: "Noto Sans";
    src: local("NotoSans-ExtraLight"), url(../fonts/Noto_Sans/NotoSans-ExtraLight.ttf) format("truetype");
    font-weight: 200;
} */


body {
    font-family: "Poppins";
    /* background-color: #f1eef5; */
    /* background-color: #f0f0f0; */
    background-color: #ecedf1;
    /* background-color: #eeedf3; */
    font-weight: 400;
    max-width: 100%;
    font-size: 13px;
    line-height: 1.4;
    color: #000;
    letter-spacing: 0.45px;
    overflow-x: hidden;
}

:root {
    /* --prime: #3a24c7; */
    --prime: #222647;
    --prime2: #383d6e;
    --prime3: #000026;
    --prime4: #0d6efd;
    /* --color1: var(--color1); */
    /* --color1: #170f56; */
    /* --color1: #052659; */
    --color1: rgb(0, 87, 255);
    --color2: #00845d;
    --color3: #ae1519;
    --color4: #055e39;
    --color5: #6386a0;
    --color6: #0039ad;
    --color7: #00339c;
}

.celias-font {
    font-family: "Celias";
}

.page-body {
    display: flex;
    display: -ms-flexbox;
    /* min-height: 100vh; */
}

.page-main {
    width: 100%;
    margin-top: 50px;
}

.page-content {
    width: 100%;
    margin-top: 50px;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Celias" !important;
    color: #282a36 !important;
    letter-spacing: 0.3px !important;
    font-weight: 600 !important;
}

h6 {
    font-size: 16px;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

p {
    color: #9293a1;
    font-size: 12px;
    margin-bottom: 0px;
}

small,
.small {
    /* color: #aaa; */
    font-size: 11px !important;
    letter-spacing: 0.4px;
}

.v-small {
    font-size: 11px !important;
}

.extra-small {
    font-size: 10px !important;
}

.pop-font {
    font-family: "Poppins" !important;
}

.pop-regular {
    font-family: "Poppins-regular";
}


/* Modal Backdrop fix */

#location-pickup-map-100 {
    /* height: 100vh !important;
    position: relative; */
    height: calc(100% - 49px) !important;
    position: relative;
}

#location-pickup-map-100::after {
    width: 22px;
    height: 35px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background: url('https://cdn3.1superapp.com/superapp/markerpin.png');
    background-size: 22px 35px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

/* .map-bottom-div {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
} */

.map-bottom-div .div {
    box-shadow: 0 1px 4px -1px rgb(0, 0, 0, 0.3);
}

.map-bottom-div {
    position: absolute;
    bottom: 45px;
    left: 20px;
    right: 20px;
}

#root>div,
#root>div>div {
    z-index: unset;
}

.border-prime {
    border: 1px solid var(--color1);
}

.bg-light-color {
    /* background-color: #e1e7f3; */
    background-color: #f1f1f1;
}

.bg-prime1 {
    background-color: #0057ff !important;
}

.card {
    border: none !important;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 1px 3px 3px 0px;
    border-radius: 6px;
}

.border-top-dot {
    border-top: 2px dotted #ccc;
}

.border-bottom-dot {
    border-bottom: 2px dotted #ccc;
}

.border-right-white {
    border-right: 2px solid #fff;
}

.border-left-white {
    border-left: 2px solid #fff;
}

.scroll-auto {
    overflow: auto;
}

/* .card {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 6px;
} */

.card-border {
    border: 1px solid #297ad0 !important;
}

.fs-7 {
    font-size: 14px;
}

.fs-9px {
    font-size: 9px !important;
}


.fs-10, .fs-10px {
    font-size: 10px !important;
}

.fs-12, .fs-12px {
    font-size: 12px;
}

.fs-11, .fs-11px {
    font-size: 11px;
}


.fs-16 {
    font-size: 16px;
}

.fs-20 {
    font-size: 20px;
}

.letter-spacing-normal {
    letter-spacing: normal;
}

.color-orange {
    color: #fd683e !important;
}


.custom_price_table tr {
    border: 1px solid black;
    /* width: 100% !important; */
    overflow: scroll;
}

.custom_price_table thead th {
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
    border-right: 1px solid #000 !important;
    width: 100% !important;

}

.Dropzone {
    height: 130px;
    width: 50%;
    background-color: #fff;
    /* border: 2px dashed rgb(187, 186, 186); */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    margin: 0px auto;
}

.dropzone_img {
    object-fit: cover;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 5px;
}


.th-width {
    width: 200px !important;
}

.tb-res {
    width: 100vw !important;
    overflow-x: scroll !important;
}



.custom_price_table td {
    border-left: 1px solid #000 !important;
    border-right: 1px solid #000 !important;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;

    /* width: 80% !important; */
}

/* .bg-image {
    background-image: url("../../assets/img/1.jpg");
} */


.row {
    margin: 0px !important;
}

.shipment-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover !important;
}


#map-live-track {
    height: 100vh;
    position: relative;
}

.custom-swipe-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 11;
    border-bottom: none !important;
    background-color: #fff;
}

.map-over {
    z-index: 9;
    position: absolute;
    height: auto !important;
    border-radius: 0 !important;
    width: 100%;
}

#map-live-track:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

label {
    color: #555 !important;
    font-size: 12px !important;
}

.text-dark {
    color: #282a36 !important;
}

.text-blue {
    color: #3955f3 !important;
}

.text-darkgray {
    color: #555555 !important;
}

.text-green {
    color: #008000 !important;
}

.text-red {
    color: #d30000;
}

.text-gray {
    color: #9293a1 !important;
}

.text-grey-1 {
    color: #505559 !important;
}

.text-prime {
    color: var(--color1) !important;
}

.text-cyan {
    color: darkcyan !important;
}

.text-red {
    color: red !important;
}

.text-orange {
    color: darkorange !important;
}

.text-goldenrod {
    color: goldenrod !important;
}

.text-violet {
    color: #03058d !important;
}

.text-darkgreen {
    color: #006800 !important;
}

.text-custom-gray {
    color: #7d7e90;
}

.text-black {
    color: #000000 !important;
}

.bg-fff {
    background-color: #fff !important;
}


.bg-prime {
    background-color: var(--color1);
}

.bg-light-prime {
    /* background-color: #e7ddff; */
    background-color: #e9edff;
}

.bg-light-green {
    background-color: #eaffe6;
}

.bg-light-red {
    background-color: #ffe6e6;
}

.bg-red {
    background-color: red;
}

.bg-green {
    background-color: green;
}

.bg-light-gray {
    background-color: #E6E8EC;
}

.bg-lightprime {
    background-color: #e9f9fd;
}

.bg-light-blue {
    background-color: #F3F6FC !important;
}

.h-42 {
    height: 42px;
}

.radius {
    border-radius: 6px !important;
}

.radius-top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.list:hover {
    background: #faf6ff !important;
    border-radius: 3px;
}

.head-bar {
    background: #fff !important;
    padding: 14px 14px 10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    position: sticky;
    top: 50px;
    z-index: 10;
}

.img-radius {
    border-radius: 50%;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bank-img {
    width: 20px;
    height: 20px;
}
.map-collection {
    width: 60px;
    height: 55px;
    object-fit: cover;
    border-radius: 10px;
}
.end {
    display: flex;
    align-items: center;
    justify-content: end;
}

.btn-text {
    box-shadow: none !important;
}

.btn-white {
    /* border: 1px solid #777; */
    border-radius: 5px;
    color: #777;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 20px 2px;

}

.btn-outline-red {
    color: #eb0000;
    background-color: white;
    border: 1px solid red;
    border-radius: 50px;
    padding: 3px 15px;
}

.btn-custom-outline {
    color: var(--color1) !important;
    background-color: #fff !important;
    /* border: 1px solid var(--color1) !important; */
    border-radius: 20px;
    box-shadow: 0 1px 4px -1px rgb(0, 0, 0, 0.3);
}

.btn-prime-outline {
    border: 1px solid var(--color1);
    border-radius: 5px;
    color: var(--color1);
    background-color: #fff;
}

.btn-primary-outline {
    border: 1px solid #0d6efd;
    border-radius: 5px;
    color: #0d6efd;
    background-color: #fff;
}

.btn-primary-outline:hover {
    color: #fff;
    background-color: #0d6efd;
}

.btn-secondary-outline {
    border: 1px solid #aaa;
    background: #fff;
    color: #222;
}

.form-check-input-custom:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input-custom:checked {
    background-color: #297ad0;
    border: none !important;
}


.form-check-input-custom[type="checkbox"] {
    border-radius: 1.25em;
}

.form-check-input-custom {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    /* color-adjust: exact; */
}

.gallery_img {
    width: 100%;
    height: 140px;
    object-fit: contain;
    background-color: #f7f7f7;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.hr-dotted {
    border: none;
    height: 1px;
    background-color: #000;
    background: repeating-linear-gradient(90deg, #000, #000 6px, transparent 6px, transparent 12px)
}

.v-small {
    font-size: 11px !important;
}

.v-medium {
    font-size: 15px !important;
}

.extra-small {
    font-size: 10px !important;
}

.m-auto {
    margin: auto !important;
}

.m-auto-map {
    margin: 10px 5% auto !important;
}

/* .map-search-bar {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: 10px;
    margin-right: 10px;
    z-index: 1500;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
} */

.w-90 {
    width: 90% !important;
}

.w-fit {
    width: fit-content !important;
}

.map_over_layer {
    position: absolute;
    background: linear-gradient(to top, rgb(0, 0, 0), transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    bottom: 0px;
    padding: 3px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-weight: 600;
}

.img_over_content_gallery {
    position: absolute;
    background: linear-gradient(to top, rgb(0, 38, 71), transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    bottom: 3px;
    padding: 5px;
    width: 96%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
}

.input-icons {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* background-color: #005d86; */
    width: 100%;
    height: 36px;
    align-items: center;
}

.right-0 {
    right: 0px !important;
}

.position-check {
    position: absolute;
    top: 3px;
    right: 5px;
}

/* Gallary css */

.Dropzone {
    height: 130px;
    width: 50%;
    background-color: #fff;
    /* border: 2px dashed rgb(187, 186, 186); */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    margin: 0px auto;
}

.dropzone_img {
    object-fit: cover;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 5px;
}


.FileInputNone {
    display: none;
}


.add_img {
    object-fit: contain;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 5px;
}

.over-img {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.img-ss {
    height: 120px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid #eee;
}

.selected_img {
    /* background-color: #dbe9ff; */
    border: 1px solid var(--prime);
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 1px 3px 3px 0px;
    border-radius: 6px;
    padding: 3px;
}

.cover_img {
    object-fit: contain;
    width: 100%;
    height: 100px;
}

.cover_img_brand {
    object-fit: cover;
    width: 100%;
    height: 100px;
}

.overflow-img-scroll {
    overflow-y: scroll;
    /* min-height: 200px; */
    max-height: 300px;
    scrollbar-width: none;
    box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
    background-color: #edf2f9;
    border-radius: 10px;
}


/* Sidebar css */
.search-bar {
    border: none !important;
    letter-spacing: 0.2;
    background-color: none !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.sidebar {
    color: #000;
    width: 100%;
    background: #fff;
    transition: margin-left 0.3s ease;
    box-shadow: 0px 1px 5px #ccc;
    position: fixed;
    z-index: 10;
    bottom: 0;
    top: 50px;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;

}

.sidebar-options {
    display: flex !important;
    justify-content: end;
    align-items: center;
}

.sidebar-footer {
    margin-top: 40px;
    padding: 0 10px;
    margin-bottom: 40px;
}

.sidebar-sticky {
    /* width: 100%; */
    /* margin-left: 15px;
    margin-right: 15px; */
    /* overflow-y: auto; */
    /* height: calc(100vh - 350px); */
    /* scrollbar-width: none !important; */
}

.sidebar-header {
    position: sticky;
    padding: 10px;
}

.uppercase {
    text-transform: uppercase !important;
}

.sidebar-brand {
    height: 90px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
    color: #555 !important;
}

.sidebar-brand img {
    max-height: 90px;
}

.page-sidebar {
    display: none;
}

.sidebar-sticky ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-sticky ul li a {
    margin-bottom: 1px;
    padding: 10px 20px;
    display: block;
    text-decoration: none;
    color: #777 !important;
    font-size: 12px;
    font-weight: 500;
    /* font-family: "poppins-medium"; */
    letter-spacing: 0.8px;
    /* text-transform: uppercase; */
    /* border-bottom: 1px solid #eee; */
}

.sidebar-sticky>ul>li:first-child>a {
    /* border-top: 1px solid #eee; */
}

.sidebar-sticky ul>li>a.active~.sidebar-dropdown-menu ul>li>a {
    /* background: #f8f8f8; */
}

/* .sidebar-sticky ul>li>a:hover, */
.sidebar-sticky ul>li>a.active {
    background: #f2f6fd !important;
    /* border-bottom-color: #eee; */
    /* color: var(--color1) !important; */
    /* border-right: 3px solid var(--color1) !important; */
    /* border-radius: 10px; */
    font-weight: bold;
    color: #000000 !important;
}

@media(min-width:768px) {
    .sidebar-sticky ul>li>a:hover {
        background: #f2f6fd !important;
        font-weight: bold;
        color: #000000 !important;
    }

    .navbar-profile {
        position: sticky;
        top: 0px;
    }

    .custom-container {
        /* width: 70%; */
        width: 35% !important;
        margin: auto;
    }
}

.sidebar-sticky ul li a p {
    font-weight: 600 !important;
    /* font-family: "poppins-medium"; */
    color: #dfdfdf;
    font-size: 12px !important;
    letter-spacing: 0.8px !important;
}

.sidebar-sticky ul>li>a>i {
    margin-right: 6px;
    color: #555;
    width: 20px;
    text-align: center;
}

.sidebar-sticky ul>li>a>img {
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.sidebar-sticky ul>li>a>span {}

.sidebar-dropdown .sidebar-dropdown-menu {
    display: none;
    /* padding-top: 3px; */
}

.sidebar-dropdown .sidebar-dropdown-menu ul li a {
    padding-left: 40px;
}

.sidebar-dropdown .sidebar-dropdown-btn.active~.sidebar-dropdown-menu {
    display: block;
}

.sidebar-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    padding: 10px;
    z-index: 1301;
    box-shadow: none !important;
}

.sidebar-close span:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    width: 100%;
    height: 100%;
    border-left: 2px solid #888;
    transform: rotate(45deg);
}

.sidebar-close span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -40%;
    width: 100%;
    height: 100%;
    border-right: 2px solid #888;
    transform: rotate(-45deg);
}

.sidebar-dropdown-arrow {
    float: right;
    margin-top: 2px;
}

.sidebar-toggler {
    position: relative;
    outline: 0 !important;
    box-shadow: none !important;
    border: 0;
    padding: 0 0 0 10px;
}

.sidebar-toggler span {
    width: 22px;
    height: 2px;
    background-color: #333;
    margin: 4px 0;
    display: block;
}

.sidebar-username {
    text-decoration: none !important;
    color: #6c757d !important;
}

.sidebar-options>a,
.sidebar-options>button {
    background: transparent;
    border: 0;
    outline: 0;
    text-decoration: none !important;
    color: #fff;
    margin-top: 5px;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
}

.sidebar-options>a:hover,
.sidebar-options>button:hover {
    background: rgba(255, 255, 255, 0.2);
}

/*  */
.single_line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* Navbar css */

.navbar-custom {
    background: #fff;
    height: 50px;
    border-bottom: 1px solid #f3f3f3;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.navbar-custom .logo-img {
    max-height: 35px;
    max-width: 200px;
}

.navbar-custom-new {
    background: #fff;
    height: 50px;
    border-bottom: 1px solid #f3f3f3;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.navbar-custom-new .logo-img {
    max-height: 35px;
    max-width: 200px;
}

.navbar-custom {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 14px;
    padding-right: 0px;
}

.navbar-custom .nav-link {
    padding: 15px 10px;
    color: #fff;
    font-weight: 500;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-custom .nav-link>img {
    width: 28px;
}

.navbar-custom-new {
    padding-top: 0;
    padding-bottom: 0;
    /* padding-left: 20px; */
    padding-right: 0px;
}

.navbar-custom-new .nav-link {
    padding: 15px 10px;
    color: #fff;
    font-weight: 500;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-custom-new .nav-link>img {
    width: 28px;
}

.navbar-search>.dropdown {
    width: 100%;
}

.navbar-profile {
    color: #fff;
    text-align: center;
    /* padding: 20px 0px; */
    background-color: #fff;
    /* position: sticky;
    top: 0px; */
}

.navbar-profile-sm {
    max-width: 100px;
    max-height: 100px;
    border-radius: 100%;
    margin-bottom: 5px;
}

/*  */
.active-tab-view {
    border-radius: 20px;
    background-color: var(--color1);
    color: #fff;
    padding: 6px 10px;
    font-size: 12px;
}

.tab-view {
    border-radius: 20px;
    background-color: #e9e9e9;
    padding: 6px 10px;
    font-size: 12px;
}

/* Modal css */

.border-bottom-prime {
    border-bottom: 1px solid var(--color1) !important;
}

.modal-header {
    border-bottom: none !important;
}

.modal-content {
    border: none !important;
}

.modal-footer {
    border-top: none !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.modal-dialog {
    /* height: 100vh; */
}

.modal {
    padding: 0px !important;
}

/*  */

a {
    text-decoration: none !important;
    color: #000;
}

.btn {
    font-family: "Poppins" !important;
    border-radius: 20px;
    letter-spacing: 0.6px !important;
    padding: 6px 20px;
    font-size: 12px !important;
}

.btn-sm {
    font-size: 11px !important;
    font-weight: 500;
    padding: 4px 18px;
}

.text-shrink {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.toast.notification {
    cursor: pointer;
    border: 0;
    position: fixed;
    z-index: 1200;
    bottom: 20px;
    left: 50%;
    margin-left: -175px;
    width: 100%;
    background: #333;
    color: #fff;
}

.dropdown-menu {
    font-size: 14px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none !important;
    z-index: 4 !important;
    padding: 5px;
}

.form-control,
.form-select {
    font-size: 14px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 0.5rem 2.25rem 0.5rem 0.75rem;
    border-radius: 20px;
}

.form-input {
    font-size: 14px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 0.5rem 2.25rem 0.5rem 0.75rem;
    border-radius: 20px;
    width: 100%;
}

.form-switch {
    /* padding-left: 0px !important; */
}

.group-select .form-select {
    font-size: 12px !important;
    padding: 0.375rem 2rem 0.375rem 1rem !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.form-control:hover,
.form-select:hover {
    border-color: #4d57eb;
}

.form-control:focus,
.form-select:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.form-input:hover {
    border-color: #4d57eb;
}

.form-control:focus,
.form-input:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.input-group-text {
    font-size: 14px;
}

.cursor {
    cursor: pointer;
}

.pagination\:container {
    display: flex;
    align-items: center;
    justify-content: end;
}

.arrow\:text {
    display: block;
    vertical-align: middle;
    font-size: 13px;
    vertical-align: middle;
}

.pagination\:number {
    --size: 32px;
    --margin: 6px;
    margin: 0 var(--margin);
    border-radius: 6px;
    background: #e1e0e0;
    max-width: auto;
    min-width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 6px;
}

.pagination\:number:hover {
    background: lighten(#202020, 3%);
}

.pagination\:number.active {
    background: lighten(#202020, 3%) !important;
}

.pagination\:active {
    background: #4891ff !important;
    color: #fff !important;
    position: relative;
}

.btn-blue-outline {
    border: 1px solid #0d6efd;
    border-radius: 6px;
    color: #0d6efd;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.btn-red-outline {
    border: 1px solid #d30000;
    border-radius: 6px;
    color: #d30000;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.btn-orange-outline {
    border: 1px solid #e78300;
    border-radius: 6px;
    color: #e78300;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.tabs {
    display: flex;
}

.tabs .tabs-head {
    padding: 0px 10px;
    cursor: pointer;
}

.tabs .tabs-head p {
    font-size: 13px;
    padding: 6px 10px;
}

.text-cl-green {
    color: green;
}

.text-cl-blue {
    color: rgb(31, 31, 138);
}

.f_tab p {
    color: #979797;
    font-weight: 600 !important;
    background: transparent !important;
    /* border: 1px solid #aaa;
    border-radius: 5px; */
    cursor: pointer;
}

.active_tab p {
    color: #0d6efd;
    font-weight: 600 !important;
    background: #d7e6fd;
    border-radius: 5px;
    cursor: pointer;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #dddddd !important;
    outline: 0;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #fff !important;
}

.accordion-button::after {
    width: 1.2rem !important;
    height: 1.2rem !important;
    background-size: 1.2rem !important;
}

.accordion-item {
    background-color: #fff;
    border: none !important;
}

.accordion-item:last-of-type .accordion-collapse {
    margin-top: 10px;
}

.accordion-body {
    padding: 0 !important;
}

.product-img {
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
}

.MuiDrawer-paperAnchorBottom {
    /* min-height: 120px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.MuiDrawer-paperAnchorBottom ul {
    list-style: none !important;
}

.MuiAccordion-root::before {
    background-color: transparent !important;
}

.MuiDrawer-paperAnchorRight {
    width: 30%;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

.MuiAccordionSummary-expandIcon {
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.scrollbar-none {
    scrollbar-Width: none !important;
}

.extras-small {
    font-size: 9px;
}

.w-12px {
    width: 12px;
    height: 12px;
}

.w-14px {
    width: 14px;
    height: 14px;
}

.w-9px {
    width: 9px;
    height: 9px;
}

.w-22px {
    width: 23px;
    height: 22px;
}

.w-20px {
    width: 17px;
}

.w-10px {
    width: 10px;
}

.w-8px {
    width: 8px;
}

/* .w-20 {
    width: 20% !important;
    
} */

.w-70 {
    width: 70% !important;
}

.w-30 {
    width: 30% !important;
}

.w-80 {
    width: 80% !important;
}


.w-10per {
    width: 10%;
}

.w-20per {
    width: 20%;
}

.w-30per {
    width: 30%;
}

.w-70per {
    width: 70%;
}

.w-80per {
    width: 80%;
}

/* .w-20 {
    width: 20% !important;
} */

.w-30px {
    width: 30px;
    height: 30px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}


.css-b62m3t-container {
    z-index: unset;
}

.Price {
    z-index: 8 !important;
}

.bottom-position-fix {
    position: relative;
    bottom: 0;
}


.bottom-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    z-index: 10;
}

.bottom-fix {
    position: fixed;
    bottom: 0;
    z-index: 10;
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    width: 100%;
}

.rs-table-hover .rs-table-row:hover {
    background-color: #e0f4fa !important;
}

.para-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.disc-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select__control,
.select__menu {
    text-align: left !important;
}

.dropdown-toggle::after {
    margin-left: 0 !important;
    content: none !important;
}

.color-orange {
    color: #fd683e;
}

.overflowY-auto {
    overflow-y: scroll;
    height: 100vh;
}

.overflowY-500 {
    overflow-y: scroll;
    height: 500px;
}

.scrollw-none {
    scrollbar-width: none !important;
}

.overflowY {
    overflow-y: scroll;
    max-height: 300px;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflowX-hidden {
    overflow-x: hidden !important;
}

.overflowY-hidden {
    overflow-y: hidden !important;
}

.border-bottom-black {
    border-bottom: 1px solid #444 !important;
}

.sticky-60px {
    position: sticky;
    top: 60px;
    z-index: 5;
}

.bank-img {
    width: 20px;
    height: 20px;
}

.delivery-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 6px;
}

/* 
.md-none {
    display: block;
}

.md-block {
    display: block !important;
}

.sm-none {
    display: none !important;
} */

/* .sm-block {
    display: block !important;
} */

/* Media quries */



.delivery_status_vertical p {
    width: 85% !important;
}

.delivery_status_vertical li {
    display: flex;
    color: #999;
}

.delivery_status_vertical time {
    position: relative;
    padding: 0 0.8em 1.5em 0 !important;
    font-size: 9px !important;
    color: #999696;
    letter-spacing: 0.2px;
    font-family: "Celias" !important;
}

.delivery_status_vertical time::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    transform: translateX(50%);
    border-radius: 50%;
    background: #18e807;
    border: 2px #18e807 solid;
    width: 10px;
    height: 10px;
}

.delivery_status_vertical .timeline {
    padding: 0 1em 1.5em 0.8em;
    position: relative;
    /* font-size: 12px; */
    width: 75%;
    /* color: #444; */
    letter-spacing: 0.4px;
}

.delivery_status_vertical .timeline::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    border-left: 1px #13dd02 solid;
}

.delivery_status_vertical strong {
    /* display: block; */
    font-weight: bold;
    color: #555;
}

.delivery_status_vertical {
    margin: 1em 0;
    width: 100%;
    padding: 0;
    position: relative;
}

.delivery_status_vertical,
.delivery_status_vertical *::before,
.delivery_status_vertical *::after {
    box-sizing: border-box;
    font-family: arial;
}

.infinite-scroll-component__outerdiv {
    padding: 0px !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
}


@media(min-width:768px) {


    .md-bottom-fixed {
        bottom: 0px;
        position: fixed;
        z-index: 3;
        left: 220px;
        width: 40%;
    }


    .delivery_status_vertical time {
        width: 15% !important;
    }

    .sticky-md-50px {
        position: sticky;
        top: 50px;
        z-index: 6;
    }

    .sticky-md-100px {
        position: sticky;
        top: 100px;
        z-index: 5;
    }

    .sticky-md-130px {
        position: sticky;
        top: 130px;
        z-index: 5;
    }

    .md-fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 99;
    }

    .page-content {
        margin-left: 220px;
    }

    .fs-6 {
        height: 16px;
    }

    .md-text-end {
        text-align: right !important;
    }

    .d-md-block {
        display: block;
    }

    .md-ps-0 {
        margin-left: 0 !important;
    }

    .mb-md-0 {
        margin-bottom: 0 !important;
    }

    .mt-md-3 {
        margin-top: 1rem;
    }

    .bottom {
        bottom: 0px;
        position: fixed;
        width: 30%;
    }

    .color-orange {
        color: #fd683e;
    }

    .sidebar {
        margin-left: 0;
        width: 220px;
    }

    .overflow-page-hidden {
        overflow: hidden !important;
        width: calc(100% - 220px) !important;
    }


    .table-head-bar {
        /* position: fixed; */
        width: calc(100% - 220px) !important;
        /* z-index: 9;
        top: 50px; */
    }

    .table-head-bar-sm {
        position: fixed;
        z-index: 9;
        top: 50px;
    }

    .sidebar .sidebar-header .sidebar-close {
        display: none;
    }

    .md-right {
        text-align: right !important;
    }

    .md-end {
        justify-content: end;

    }

    .mb-md-0 {
        margin-bottom: 0 !important;
    }

    .fix-width {
        width: 100%;
        table-layout: fixed;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .md-text-end {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .table-page {
        margin-top: 130px !important;
    }

    .table-headpage50 {
        margin-top: 50px !important;
    }

    .table-headpage80 {
        margin-top: 80px !important;
    }

    .table-page-180 {
        margin-top: 180px !important;
    }

}


@media(max-width:768px) {

    .delivery_status_vertical time {
        width: 25% !important;
    }

    .head-bar {
        top: -3px !important;
    }

    .sm-bottom-custom-fixed {
        bottom: 49px;
        position: fixed;
        z-index: 3;
        left: 0;
        width: 100%;
    }

    .sm-pb-0 {
        padding-bottom: 0px !important;
    }

    .sm-px-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .border-bottom-sm {
        border-bottom: 1px solid #ccc !important;
    }

    .px-sm-3 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .fix-width {
        width: 100%;
    }

    .full-map-picker .map-current-location {
        right: 0px !important;
        left: auto !important;
        bottom: 80px !important;
    }

    .mb-sm-2 {
        margin-bottom: 1rem !important;
    }

    .mb-sm-1 {
        margin-bottom: 0.5rem !important;
    }

    .mb-sm-3 {
        margin-bottom: 1.5rem !important;
    }

    .mt-sm-2 {
        margin-top: 1rem;
    }

    .sm-end {
        text-align: end !important;
    }

    .sm-left {
        text-align: left;
    }

    .sticky-sm {
        position: sticky;
        top: 0px;
        z-index: 10;
    }

    .sticky-sm-50px {
        position: sticky;
        top: 48px;
        z-index: 6;
    }

    /* .md-fixed-top {
        top: 0;
        position: absolute !important;
        width: 100%;
    } */

    .sm-fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 99;
    }

    .page-content {
        margin-top: 50px !important;
    }

    .sidebar.active {

        height: 100vh;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        display: block;
        transition-property: top, left;
        transition-duration: 1s, 1s;
        transition-delay: 0s, 1s;
        z-index: 1332;
        background-color: rgb(0, 0, 0, 0.3) !important;
    }

    .sidebar.active .sidebar-w {
        /* width: calc(100% - 20%); */
        background-color: #fff !important;
        height: 100%;
    }

    .sidebar-footer {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .sidebar {
        transition: 6.5s ease-in-out;
        display: none;
    }

    .full-map .map-current-location {
        right: 0 !important;
        left: auto !important;
        bottom: 90px !important;
        border-radius: 50%;
        outline: 0;
        width: 45px;
        height: 45px;
        text-align: center;
    }

    .color-orange {
        color: #fd683e;
    }

    .fs-6 {
        height: 10px;
    }

    .modal-dialog {
        max-width: 100% !important;
        margin: 0px !important;
    }

    .sm-p-0 {
        padding: 0px !important;
    }

    .sm-mt-2 {
        margin-top: 0.5rem !important;
    }

    .sm-mt-3 {
        margin-top: 1rem !important;
    }

    .md-none {
        display: block;
    }

    .md-block {
        display: block !important;
    }

    .sm-none {
        display: none !important;
    }

    .sm-block {
        display: block !important;
    }

    .d-md-none {
        display: none !important;
    }

    .d-sm-none {
        display: none;
    }



    .d-sm-block {
        display: block !important;
    }

    .sm-mb-2 {
        margin-bottom: 1rem;
    }

    .bottom {
        bottom: 0px;
        position: fixed;
        width: 100%;
    }

    .pt-sm-2 {
        padding-top: 1rem;
    }
}


@media (max-width: 991px) {

    .full-map .map-current-location {
        right: 0 !important;
        left: auto !important;
        bottom: 90px !important;
        border-radius: 50%;
        outline: 0;
        width: 45px;
        height: 45px;
        text-align: center;
    }

    .color-orange {
        color: #fd683e;
    }



    .overlay-sidebar {
        width: 100%;
        background-color: rgb(0, 0, 0, 0.3);
    }


    .navbar-search {
        display: none;
    }

    .navbar-search.active {
        position: absolute;
        left: 10px;
        right: 10px;
        background: #fff;
        z-index: 111111;
        top: 0;
        height: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }

    .navbar-search.active>. {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .border-right-md {
        border-right: 1px solid #dee2e6;
    }



    .map-current-location {
        font-size: 20px;
        appearance: button;
        background-color: #fff;
        border: 0;
        border-radius: 2px;
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        margin: 10px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        right: 0px !important;
        /* left: auto!important;
        bottom: 80px!important; */
        color: #343a40;
        padding: 0;
        outline: 0 !important;
        text-align: center;
        vertical-align: middle;
    }

    .map-current-location.map-type {
        position: absolute;
        bottom: 150px !important;
        right: 0;
        left: auto !important;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        font-size: 20px;
        outline: none;
    }

    .map-current-location.map-type.map-type-picker {
        bottom: 135px !important;
        /* top: 125px; */
        width: 40px;
        height: 40px;
    }

    .color-orange {
        color: #fd683e;
    }

    .fs-6 {
        height: 16px;
    }

    .page-sidebar {
        width: 27%;
    }



    .navbar-search {
        width: 25%;
    }

    .navbar-custom .logo-img {
        max-height: 35px;
        width: auto;
        max-width: 200px;
    }

    .navbar-custom-new .logo-img {
        max-height: 35px;
        width: auto;
        max-width: 200px;
    }

    .navbar-brand.navlogo {
        width: 17%;
        text-align: center;
    }


    .sidebar-sticky::-webkit-scrollbar {
        width: 5px;
        height: 4px;
    }

    .sidebar-sticky::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.2);
    }

    .sidebar-sticky::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.3);
    }

    .sidebar-sticky::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.4);
    }

    .sidebar-sticky {
        scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.2);
        scrollbar-width: thin;
    }


}

.sticky-100px {
    position: sticky;
    top: 100px;
    z-index: 6;
}

.sticky-left {
    left: 12px !important;
    position: sticky !important;
    z-index: 4 !important;
    border-right: 1px solid #ddd;
}

.scroll-to-left {
    margin-left: 17%;
}


/* th.col {
    width: 100px !important;
    padding: 10px 20px;
}

.table th.col {
    width: 100px !important;
}

tbody {
    display: block;
    max-height: 500px;
    overflow-y: scroll;
    width: 100%;
}

thead {
    display: block;
}

th,
td {
    width: 120px !important;
} */

/* table {
    border-collapse: collapse;
    position: relative;
}


.table-container {
    overflow: scroll;
    max-height: 700px;
    border: 1px solid red;
    table-layout: fixed;
}

thead {
    position: sticky;
    top: 0;
    background: white;
    white-space: nowrap;
    border: 1px solid #ccc;
    z-index: 5;
}

thead th {
    border: 1px sold #ccc !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
} */

table {
    /* table-layout: fixed; */
    border-collapse: collapse;
    width: 100%;
}

table thead th {
    white-space: nowrap;
}

/* table{
    position: relative;
    border-collapse: collapse;

}

.table-container{
    overflow: auto !important;
    height: 800px;
    scrollbar-width: none !important;
}

thead{
    position: sticky;
    top: 16px;
    background-color: #ffffff;
} */
table.price_table tr td {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}


table.price_table {
    table-layout: auto;
    width: 100%;
}

table.price_table .input-group-text {
    padding: 0.375rem 0.4rem !important;
}

/* .table-responsive {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
} */

.sticky-top-130px {
    position: sticky;
    top: 130px;
    z-index: 5;
}

.sticky-top-150px {
    position: sticky;
    top: 150px;
    z-index: 5;
}

.sticky-top-180px {
    position: sticky;
    top: 180px;
    z-index: 5;
}

.border-left {
    border-left: 1px solid #ccc;
}

.border-right {
    border-right: 1px solid #ccc;
}

.overflowScroll-X {
    overflow-x: scroll;
    width: 100%;
}

.br-100 {
    border-radius: 100%;
}

.br-0 {
    border-radius: 0 !important;
}

.br-4 {
    border-radius: 4px !important;
}

.br-20 {
    border-radius: 20px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #fff !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.material-inputfield {
    position: relative;
}

.material-inputfield label {
    position: absolute;
    font-size: 14px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: #808080;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left center;
    pointer-events: none;
}

.material-inputfield input,
.material-inputfield select {
    font-size: 14px;
    outline: none;
    width: 100%;
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 9px 10px 7px 12px;
    color: #808080;
    transition: 0.1s ease-out;
}

.material-inputfield select {
    padding: 8px 10px 7px 7px;
}

.material-inputfield input:focus,
.material-inputfield select:focus {
    border-color: #777;
}

.material-inputfield input:focus+label,
.material-inputfield select:focus+label {
    color: #495057;
    top: 0;
    transform: translateY(-50%) scale(0.9);
}

.form-control::placeholder {
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #8e8e8e;
}

.material-inputfield input:not(:placeholder-shown)+label,
.material-inputfield select:not(:placeholder-shown)+label {
    top: 0;
    transform: translateY(-50%) scale(0.9);
}

.material-inputfield input.border-red+label,
.material-inputfield select.border-red+label {
    color: #ce1010 !important;
}

.material-inputfield.phone-number-dc input {
    padding-left: 52px;
}

.material-inputfield.phone-number-dc label.phone {
    top: 0;
    font-size: 13px;
}

.material-input-prepend {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    border: 0;
    padding-right: 0;
}

.material-inputfield.youtube-dc input {
    padding-left: 143px;
}

.material-inputfield.instagram-dc input {
    padding-left: 156px;
}

.material-inputfield.twitter-dc input {
    padding-left: 132px;
}

.material-inputfield.facebook-dc input {
    padding-left: 151px;
}

.txt {
    position: absolute;
    right: -0%;
    top: 1%;
    height: 98%;
    width: 18%;
    justify-content: center;
    align-items: center;
}

.text-cl-green {
    color: green;
}

.text-cl-blue {
    color: rgb(31, 31, 138);
    transform: rotateZ(90deg);
}

/* /////////////////////////////////////ORDER DETAILS TRACK */




/* @font-face {
    font-family: 'GoogleSans';
    src: url('../fonts/GoogleSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }
    @font-face {
    font-family: 'GoogleSans';
    src: url('../fonts/GoogleSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    }
    @font-face {
    font-family: 'GoogleSans';
    src: url('../fonts/GoogleSans-Medium.ttf') format('truetype');
    font-weight: medium;
    font-style: normal;
    } */

/* body{
        font-family: 'GoogleSans';
    }
     */
ul {
    padding-left: 0;
}

.ml-20 {
    margin-left: -20px !important;
}

.logo,
.logo:hover,
.logo:focus {
    text-decoration: none;
    font-size: 25px;
    color: #000;
}

.logo span {
    line-height: 50px;
}

.primary_header {
    padding: 15px 0;
    min-height: 80px;
}

.secondary_header .navbar-default .navbar-nav>li>a {
    color: #fff;
    text-transform: capitalize;
}

.secondary_header .navbar-default {
    border: none;
    background: transparent;
    margin: 0;
}

.secondary_header .navbar-default .navbar-nav>li>a:focus,
.secondary_header .navbar-default .navbar-nav>li>a:hover {
    color: #fff;
}

.secondary_header .navbar-default .navbar-nav>li>a {
    font-size: 18px;
    padding: 0;
}

.secondary_header .navbar-nav>li {
    margin-right: 80px;
    float: none;
    display: inline-block;
}

.secondary_header .navbar-nav>li:last-child {
    margin-right: 0px;
}

.secondary_header .navbar-nav {
    margin: 15px 0;
    text-align: center;
    float: none;

}

.post_order_wrap {
    padding: 24px 0 48px;
}

.post_order_wrap>a {
    font-size: 18px;
    margin-bottom: 35px;
    display: block;
    color: #285ddb;
    padding-left: 4px;
}

.post_order_wrap>a i {
    font-size: 32px;
    position: relative;
    top: 3px;
    left: -5px;
    color: #285ddb;
}

.post_order_wrap a:hover,
.post_order_wrap a:focus {
    text-decoration: none;
    color: #004989;
}

.content {
    background: #ededed;
}

.post_order_info {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
}

.post_order_info .title {
    font-size: 17px;
    margin-bottom: 32px;
    display: block;
    border-bottom: 1px solid #CFD4D6;
    padding-bottom: 25px;
    color: #555;
}

.radio_box li {
    float: left;
    width: 14%;
    list-style: none;
}

.radio_box span {
    margin-left: 17px;
    color: #555;
}

.radio_icon {
    height: 22px;
    width: 22px;
    border: 1px solid #CFD4D6;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    position: absolute;
    top: 9px;
    left: -20px;
}

.radio_icon:before {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #000;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    top: 5px;
    display: none;
}

.radio_box {
    padding-left: 18px;
    margin-bottom: 25px;
}

.error {
    color: red;
    display: block;
    margin-bottom: 25px;
}

.success {
    color: green;
    display: block;
}

.radio_box input[type="radio"] {
    height: 22px;
    width: 22px;
    position: relative;
    top: 5px;
    z-index: 9;
    opacity: 0;
}

.radio_box input[type="radio"]:checked+.radio_icon:before {
    display: block;
}

.post_order_info label {
    margin-bottom: 12px;
    display: block;
}

.contact_input {
    padding: 0;
    margin-bottom: 34px;
}

.contact_input li {
    float: left;
    list-style: none;
    width: 47.5%;
    margin-right: 5%;
}

.contact_input li:last-child {
    margin-right: 0;
}

.contact_input input {
    width: 100%;
    line-height: 50px;
    height: 50px;
    padding: 0 23px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    border: 1px solid #CFD4D6;
}

.date_info {
    padding: 0;
    margin-bottom: 74px;
}

.date_info li {
    float: left;
    list-style: none;
    margin-right: 3%;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    color: #555;
}

#refused_resons li {
    width: 25%;
}

#others_resons li {
    width: 33.33%;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-2px {
    margin-bottom: 2px;
}

.date_info li:hover,
.date_info li:focus {
    border-color: #4EA24A;
}

.post_order_info .contact_input+label {
    margin-bottom: 37px;
}

.drop_down_select .panel-default {
    float: left;
    width: 47.5%;
    margin-right: 5%;
    margin-top: 0 !important;
    margin-bottom: 60px;
}

.post_order_info .panel-default>.panel-heading {
    background: transparent;
}

.drop_down_select .panel-default:nth-child(2n) {
    margin-right: 0;
}

.post_order_info .panel-heading {
    padding: 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.post_order_info .panel-default>.panel-heading a:focus,
.post_order_info .panel-default>.panel-heading a:hover {
    color: #fff;
    background: #000;
    outline: none;
}

.post_order_info .submit_btn {
    display: block;
    min-width: 270px;
    line-height: 50px;
    height: 50px;
    color: #fff;
    padding: 0;
    font-size: 18px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-bottom: 30px;
}

.post_order_info .submit_btn:hover {}

@media (min-width: 912px) and (max-width: 912px) {
    footer {}
}

footer {
    padding: 15px 0 15px;

}

footer a {
    color: #555;
}

footer a:hover,
footer a:focus {
    text-decoration: none;
}

.contact_detail {
    margin-top: 9px;
}

.social-media {
    margin-left: 8px;
}

.logo img {
    max-width: auto;
    max-height: 50px;
}

.bg-request-btn.button {
    border: 1px solid #2960db;
    background-color: #FFFFFF;
    color: #2960db;

    width: 173px;
    height: 48px;
    border-radius: 6px;
    font: normal normal medium 16px/19px SF Pro;
}

.foot_logo a {
    vertical-align: 4px;
}

.post_order_info .panel-title>a {
    display: block;
    padding: 18px 25px;
    position: relative;
    color: #555;
}

.post_order_info .panel-title>a i {
    position: absolute;
    right: 11px;
    font-size: 32px;
    top: 12px;
}

.panel-heading a[aria-expanded="true"] .fa-angle-down::before {
    content: "\f106";
}

.post_order_info .panel-body .radio_box li {
    float: none;
    width: 100%;
}

.post_order_info .panel-body .radio_box {
    margin-bottom: 12px;
}

.post_order_info .panel-body {
    padding: 20px 40px;
}

.post_order_info .panel-title>a[aria-expanded="true"] {
    background: #000;
    color: #fff;
}

.post_order_info .panel-group {
    margin-bottom: 0;
}





.inner_page .hp_cards {
    margin-top: 42px;
}

.inner_page .hp_cards_info h5 {
    font-size: 15px;
    margin: 9px 0 0;
    border-bottom: 1px solid #CFD4D6;

    padding-bottom: 21px;
    padding-left: 35px;
    font-weight: 400;
}

.inner_page .hp_cards_info {
    padding: 17px 15px 1px;
    background: #fff;
    border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
}

.inner_page .hp_cards_info h5 i.fa-cube,
.inner_page .hp_cards_info h5 i {
    margin-right: 8px;
    font-size: 22px;
}

.inner_page .hp_cards_info h5 a i {
    margin-right: 5px;
    font-size: 25px;
    margin-top: 1px;
    position: relative;
    top: 2px;
}

.inner_page .hp_cards_info h5 a {
    font-size: 16px;
    color: #285ddb;
}

.inner_page .hp_cards_info h5 a:hover,
.inner_page .hp_cards_info h5 a:focus {
    text-decoration: none;
    color: #285ddb;
}

.inner_page .hp_cards_info .mb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.right_info {
    width: 65%;
    word-break: break-word;
    color: #737373;
}

.orange_text {
    color: #F28419;
}

.courier_info span,
.courier_info a {
    display: block;
}

.courier_info {
    background: #fff;
    padding: 23px 23px 0px 18px;
}

.courier_logo {

    background-size: contain;
    -webkit-background-size: contain;
    border-radius: 5px;
    width: 75px;
    height: 65px;
    margin-right: 15px;
}

.courier_info span:first-child {
    margin-bottom: 5px;
    margin-top: 9px;
}

.tracking_id {
    color: #285ddb;
}

.courier_info a,
.courier_info a:focus,
.courier_info a:hover {
    text-decoration: none;
    color: #285ddb;
}

.delievery_info {
    padding: 25px 15px 25px;
    position: relative;
    background: #fff;
    overflow: hidden;
    border-radius: 0 0 10px 10px;
    -webkit-border-radius: 0 0 10px 10px;
    margin-bottom: 45px;
}

.delievery_info ul {
    padding: 0;
    width: 76%;
    float: right;
    margin: 0;
}

.delievery_info li {
    list-style: none;
    background: #fff;
    padding: 24px 0px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    margin-right: 10px;
}

.delievery_info li:last-child {
    margin-bottom: 0;
}

.delievery_info li span:first-child {
    display: block;
    margin-bottom: 12px;
}

.delievery_info li span span {
    display: inline;
}

.status_cont {
    max-width: 90%;
}

.date_info_wrap {
    position: absolute;
    left: -118px;
    top: 28px;
    text-align: right;
    font-size: 13px;
}

.date_info_wrap .date {
    color: #434343;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.date_info_wrap .time {
    color: #454545;
}


.delievery_info li .date_info_wrap span:first-child {
    margin-bottom: 1px;
}

.delievery_list_wrap {
    max-height: 220px;
    overflow-y: auto;
}

.delievery_info li activity {
    color: #737373;
}

.delievery_info li:after {
    content: '';
    height: 100%;
    width: 2px;
    display: block;
    position: absolute;
    left: -36px;
    top: 59px;
    z-index: 9;
    border: 1px dashed #dedede;
}

.delievery_info li.active .circle_icon {
    border: 3px solid white;
}

.delievery_info li:last-child:after {
    display: none;
}

.circle_icon {
    height: 16px;
    width: 16px;
    position: absolute;
    left: 0;
    border: 3px solid white;
    box-shadow: 0 0 2px #a8a8a8;
    display: block;
    left: -43px;
    border-radius: 50%;
    background: #a8a8a8;
    z-index: 9;
    top: 42px;
}

.delievery_info li.active .circle_icon {
    background: #18F040;
}

.delievery_info li.active span span {
    color: #1EA231;
}

.check_delievery {
    font-size: 46px;
    color: #1EA231;
    width: 34px;
    height: 40px;
    display: inline-block;

    background-position: -135px -1px;
    vertical-align: middle;
}

.error_delivery {
    font-size: 46px;
    color: #1EA231;
    width: 34px;
    height: 40px;
    display: inline-block;
    color: #1EA231;

    background-position: -181px -2px;
    vertical-align: middle;
}

.recommend_info {
    background: #fff;
    padding: 18px 20px 25px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 45px;
    margin-top: 30px;
}

.recommend_info p {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 3px;
    font-weight: 400;
}

.recommend_info .pagination li a {
    background: #fff;
    border-radius: 50%;
    font-size: 16px;
    padding: 7px;
    color: #000;
    width: 35px;
    height: 35px;
    display: block;
    text-align: center;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
}

.recommend_info .pagination li a.red {
    border-color: #cc3a29;
}

.recommend_info .pagination li.active a.red,
.recommend_info .pagination li a.red:hover {
    border-color: #cc3a29;
    background: #cc3a29;
    color: #fff;
}


.recommend_info .pagination li a.yellow {
    border-color: #f7b336;
}

.recommend_info .pagination li.active a.yellow,
.recommend_info .pagination li a.yellow:hover {
    border-color: #f7b336;
    background: #f7b336;
    color: #fff;
}

.recommend_info .pagination li a.green {
    border-color: #71d53a;
}

.recommend_info .pagination li.active a.green,
.recommend_info .pagination li a.green:hover {
    border-color: #71d53a;
    background: #71d53a;
    color: #fff;
}


.recommend_info .pagination li {
    margin-right: 10px;
    display: inline-block;

}

.recommend_info .pagination li:last-child {
    margin-right: 0;
}

.extremely_arrow i,
.likely_arrow i {
    display: block;
    font-size: 26px;
    padding-left: 10px;
    margin-bottom: 5px;
}

.extremely_arrow,
.likely_arrow {
    color: #555;
    display: inline-block;
    cursor: default;

}

.likely_arrow {
    float: right;
    text-align: right;
}

.nps_pagination .arrow_wrap_pagination a.extremely_arrow {
    float: left;
    text-align: left;
}

.extremely_arrow:hover,
.likely_arrow:hover {
    text-decoration: none;
    color: #555;
}

.likely_arrow i {
    padding-right: 7px;
}

.recommend_info .pagination {
    margin-bottom: 0;
}

.arrow_wrap_pagination {
    margin-bottom: 12px;
    margin-top: 5px;
}

.recommend_info label {
    font-weight: 700;
    display: block;
    margin-bottom: 7px;
}

.recommend_info input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    background: #f2f2f2;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 27px;
    color: #a4a4a4;
}

.recommend_info button {
    font-size: 16px;
    min-width: 200px;
    height: 45px;
    line-height: 45px;
    font-weight: 700;
    padding: 0;
    color: #fff;
    border-radius: 5px;
}

.recommend_info button:hover {}

.recommend_info button:hover,
.recommend_info button:focus,
.signup_now button:hover,
.signup_now button:focus {
    color: #fff;
}

.signup_now {
    padding: 20px 20px 17px;
    background: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 45px;
}

.signup_now i.fa-mobile {
    font-size: 0px;

    background-position: 0px 0px;
    width: 39px;
    height: 42px;
    position: absolute;
    left: 29px;
    top: 27px;
}

.share {

    background-position: -47px 0px;
    width: 30px;
    height: 35px;
    display: block;
}

.share_icon_wrap {
    margin: 5px 9px 0;
}

.signup_now span {
    font-size: 16px;
    margin-left: 40px;
    color: #000;
    margin-top: 16px;
    display: inline-block;
}

.signup_now button {
    font-size: 16px;
    min-width: 200px;
    height: 47px;
    line-height: 47px;
    font-weight: 700;
    padding: 0;
    color: #fff;
    border-radius: 5px;
    margin-top: -3px;
}

.signup_now button:hover {}

.signup_now label {
    background: #f2f2f2;
    padding: 10px 15px;
    border-radius: 5px;
    margin-right: 20px;
}

.signup_now label i {
    font-style: normal;
    color: #555;
    font-size: 18px;
    font-weight: 400;
}

.signup_now label input {
    background: transparent;
    border: none;
    width: 190px;
}

.signup_now label i.plus {
    font-weight: 700;
    vertical-align: 1px;
}

.banner_section {
    margin-bottom: 45px;
}

.banner_section img {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}

.delievery_status span,
.delievery_status strong {
    display: block;
}

.delievery_status span {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 14px;
}

.delievery_status strong {}

.delievery_status .edd_day {
    font-weight: bold;
    font-size: 20px;
}

.delievery_status .edd_date {
    font-size: 100px;
    line-height: 100px;
    font-weight: normal;
}

.delievery_status .edd_month_new {
    display: inline;
    font-size: 22px;
    padding-left: 5px;
    vertical-align: text-bottom;
}

.delievery_status .edd_month {
    font-size: 16px;
}

@media (max-width: 912px) {
    .delievery_status #shipment_status {
        font-size: 30px !important;
    }
}

@media (max-width: 280px) {
    .delievery_status #shipment_status {
        font-size: 22px !important;
    }
}

.delievery_status #shipment_status {
    font-size: 38px;
    font-weight: normal;
    margin-top: 10px;
    margin: 8px 0px;
}

.delievery_status strong+span {
    font-size: 20px;
    color: #000;
}

.active_delivery {
    color: #1EA231 !important;
}

.red_color {
    color: #c81717 !important;
}

.info_color {
    color: #e8ac1a !important;
}

.delievery_status {
    border-bottom: 1px solid #CFD4D6;
    padding-bottom: 21px;
    margin-bottom: 0 !important;
}

.information_block .hp_cards_info {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    position: relative;
    padding-bottom: 2px;
}

.information_block .hp_cards_info span,
.information_block .hp_cards_info strong {
    font-size: 13px;
}

.order_detail_icon {
    width: 25px;
    height: 27px;

    display: inline-block;
    position: absolute;
    left: 16px;
    top: 21px;
}

.cta_btn_wrap {
    float: left;
    display: block;
    width: 100%;

}

.cta_btn_wrap a {
    font-size: 18px;
    color: white;
}

.cta_btn_wrap button {
    font-size: 16px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-weight: 700;
    padding: 0;
    color: #fff;
    border-radius: 5px;
    border: none;
}

.cta_btn_wrap button:hover,
.cta_btn_wrap button:focus {
    color: #fff;
}

.success_icon {

    background-position: -232px -3px;
    width: 55px;
    height: 64px;
    display: block;
    margin: 0 auto 20px;
}

.success_info strong {
    display: block;
    font-size: 38px;
    margin-bottom: 15px;
    font-weight: 500;
}

.success_info {
    padding: 81px 20px 83px;
}

.success_info span {
    font-size: 16px;
    color: #737373;
}

.foot_logo img {
    width: 50%;
    margin-top: 4px;
}

#shipment_status_label {
    margin-bottom: -15px;
    margin-top: 10px;
}

.nps_pagination .arrow_wrap_pagination {
    width: 507.84px;
    margin: 0 auto;
}

.edd_info {
    float: left;
    width: 173px;
    position: relative;
    margin-right: 55px;
}

.edd_info:last-child {
    margin-right: 0;
}

.divider_date {
    background: #000;
    height: 3px;
    width: 20px;
    display: block;
    position: absolute;
    top: 65%;
    right: -10px;
}

.delievery_status span.year_txt {
    display: inline-block;
    line-height: 0px;
    color: #333333;
}

.mt-15 {
    margin-top: 15px;
}

.db {
    display: block;
}

@media(max-width: 506px) {
    .multiple_date .edd_info {
        width: 111px;
        margin-right: 12px;
    }

    .sm-prime-border {
        border: 1px solid var(--color1);
        border-radius: 20px;
        padding: 4px 15px;
    }

    .multiple_date .edd_date {
        font-size: 60px;
    }

    .multiple_date .edd_info:last-child {
        margin-right: 0;
    }

    .multiple_date .divider_date {
        top: 60%;
        right: -1px;
    }
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: 100vh;
}

.h-100 {
    height: 100% !important;
}

.h-140px {
    height: 140px;
}

.pb-7 {
    padding-bottom: 5rem !important;
}

.p-12 {
    padding: 12px;
}

@media (min-width: 768px) and (max-width: 1200px) {

    .border-right-md {
        border-right: 1px solid #dee2e6;
    }

    .full-map .map-current-location {
        right: 0 !important;
        left: auto !important;
        bottom: 90px !important;
        border-radius: 50%;
        outline: 0;
        width: 45px;
        height: 45px;
        text-align: center;
    }

    .multiple_date .edd_info {
        width: 126px;
        margin-right: 30px;
    }

    .multiple_date .edd_info:last-child {
        margin-right: 0;
    }

    .multiple_date .edd_date {
        font-size: 72px;
    }

    .multiple_date .divider_date {
        right: -16px;
    }

}

@media (max-width: 768px) {
    
.bottom-fixed-50px {
    position: fixed;
    bottom: 48px;
    left: 0;
    width: 100%;
    height: 45px;
    z-index: 10;
}

    .text-sm-center {
        text-align: center;
    }

    .w-sm-100 {
        width: 100%;
    }

    .w-sm-50 {
        width: 50%;
    }

    .pe-sm-0 {
        padding-right: 0 !important;
    }

    .ps-sm-0 {
        padding-left: 0 !important;
    }

    .px-sm-0 {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .px-sm-1 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .px-sm-2 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    .pt-sm-3 {
        padding-top: 1.5rem !important;
    }

    .px-sm-2 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .my-sm-3 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .sm-w-100 {
        width: 100%;
    }

    .bottom-sm-50px-fixed {
        bottom: 42px;
        left: 0;
        position: fixed;
    }
}

@media (min-width: 768px) {

    .w-md-80 {
        width: 80% !important;
    }

    .out_of_stock_swipe .react-swipeable-view-container {
        box-shadow: none !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .out_of_stock_swipe .react-swipeable-view-container>div {
        width: 50% !important;
    }

    .bottom-md-0px-fixed {
        position: fixed;
        bottom: 0px !important;
        margin-right: 26px;
        right: 0;
    }

    .md-w-fill {
        width: -webkit-fill-available;
    }

    .w-md-50 {
        width: 50%;
    }

    .w-md-25 {
        width: 25%;
    }

    .text-end-md {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .w-md-40 {
        width: 40%;
    }

    .w-md-45 {
        width: 45%;
    }

    .w-md-60 {
        width: 60%;
    }

    .ps-md-2 {
        padding-left: 1rem !important;
    }

    .mt-md-5 {
        margin-top: 2.5rem !important;
    }

    .text-md-left {
        text-align: left !important;
    }

    .px-md-3 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }



    .px-md-2 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .pt-md-0 {
        padding-top: 0 !important;
    }

    .pt-md-5 {
        padding-top: 2.5rem !important;
    }

    .navfooter {
        display: none;
    }

    .bottom-fixed-md {        
    position: fixed;
    bottom: 0px;
    width: 35% !important;
    height: 45px;
    z-index: 10;
    }
}

.edd_date {
    color: blue;
}

.track_det {
    font-family: 'GoogleSans' !important;
}

#map-test {
    height: 80vh;
    position: relative;

}

/* #map {
        position: relative;
    } */

#map-test:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}



#map {
    height: 100%;
}

/* 
   * Optional: Makes the sample page fill the window. 
   */

#description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    font-family: Roboto;
    padding: 0;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    /* background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 100%;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px; */
    /* position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	margin-left: 10px;
	margin-right: 10px;
	z-index: 1500;
	background: #fff;
	box-shadow: 0 1px 4px -1px rgb(0, 0, 0, 0.3);
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px; */

    /* padding-left: 45px;
    height: 40px;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 1px 4px -1px rgb(0 0 0 / 30%);
    margin-top: 10px; */

}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

#target {
    width: 345px;
}

.pac-target-input {
    animation-name: endBrowserAutofill;
    margin-top: 10px;
}

.pac-container {
    z-index: 123333;
}

.map-search-div {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin-left: 10px;
    margin-right: 10px;
    z-index: 1500;
    background: #fff;
    box-shadow: 0 1px 4px -1px rgb(0, 0, 0, 0.3);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.map-search-bar {
    position: absolute;
    left: 0;
    right: 0;
    /* margin-left: 10px;
    margin-right: 10px; */
    z-index: 1500;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.map-layer-img {
    width: 65px;
    height: 65px;
    border-radius: 6px;
    object-fit: cover !important;
}

.map-bottom-div {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
}

.map-bottom-div .div {
    box-shadow: 0 1px 4px -1px rgb(0, 0, 0, 0.3);
}

.full-map-header .map-topbar #map-search {
    padding-left: 45px;
    height: 40px;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 1px 4px -1px rgb(0 0 0 / 30%);
    margin-top: 10px;
}


[data-rsbs-root]:after {
    z-index: 1100 !important;
    overscroll-behavior: none;
    touch-action: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

[data-rsbs-footer] {
    box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
        0 2px 0 var(--rsbs-bg);
    overflow: hidden;
    z-index: 1100 !important;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-header] {
    text-align: center;
    user-select: none;
    box-shadow: 0 1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
    z-index: 1100 !important;
    padding-top: calc(20px + env(safe-area-inset-top));
    padding-bottom: 8px;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
    z-index: 1100 !important;
    height: 200px !important;

    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    touch-action: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

/* .sticky-top {
    position: sticky;
    top: 0;
    z-index: 10;
} */


.active_head p {
    color: var(--color1) !important;
    font-weight: 600 !important;

}

.active_head {
    border-bottom: 2px solid var(--color1);
    border-radius: 2px;
}

.delivery_profile_img {
    max-height: 120px;
    max-width: 120px;
    border-radius: 6px;
    object-fit: cover;
}

.icon-14px {
    width: 14px;
    height: 14px;
}

.oneline {
    /* height: 18px !important; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.double {
    /* height: 18px !important; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
}

.trible {
    /* height: 18px !important; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logo-img {
    width: 20px;
    height: 20px;
}

.btn-radius {
    border-radius: 20px;
}

.lang-dropdown .select__indicator-separator {
    background-color: transparent !important;
}

.lang-dropdown .select__indicator {
    padding: 2px !important;
    /* display: none !important; */
    border-radius: 20px !important;
    background-color: transparent !important;
}

.lang-dropdown .select__indicator svg {
    width: 16px !important;
}

.lang-dropdown .select__control {
    min-height: 30px !important;
    border: none !important;
    border-radius: 20px;
}

.lang-dropdown .select__control--is-focused {
    min-height: 30px !important;
    border: none !important;
    box-shadow: none !important;
}

.lang-dropdown .select__single-value {
    font-size: 13px;
}

.lang-dropdown .select__value-container {
    padding: 2px 4px !important;
}

.acc-img {
    width: 100%;
    height: 60px;
}

.acc-prof {
    width: 100px;
    height: 100px;
    object-fit: cover;
    background-color: #fff;
    border: none !important;
}

.navfooter .col-2 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
}

.bottom-tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* padding: 5px 0; */
    /* z-index: 1; */
    background: #fff;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: 2px 0px 6px #ccc;
    /* padding: 5px 0px; */
}

/* .navfooter {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 4;
    border-top: 1px solid #eee;
    align-items: center;
    text-align: center;
} */

.navfooter {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    /* z-index: 4; */
    z-index: 9999;
    align-items: center;
    text-align: center;
    scroll-behavior: smooth;
}

.prof-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.table-user-img {
    /* border-radius: 50%; */
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
}

.franchise-user-img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
}

.fran-type {
    border: 1px solid #999;
    border-radius: 6px;
    text-align: center;
    padding: 5px 10px;
    width: 100%;
}

.fran-type-active {
    border: 1px solid var(--color1);
    border-radius: 6px;
    text-align: center;
    padding: 5px 10px;
    width: 100%;
    background-color: #eef;
}

.shadow-bottom {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
}

.btn-shadow {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.nav-tabs .nav-link.active {
    background-color: var(--color1) !important;
    color: #fff !important;
    border: none !important;
    border-radius: 10px;
    margin-right: 10px;
    padding: 6px 12px !important;
    font-weight: 600;
}

.nav-tabs .nav-link {
    background-color: #ebebeb !important;
    color: #444 !important;
    border: none !important;
    border-radius: 10px;
    margin-right: 10px;
    padding: 6px 12px !important;
}

.sticky-tabs .nav-tabs {
    border: none !important;
}

.outlet-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    padding: 4px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.outlet-map-img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
}

.scroll {
    overflow: scroll;
}

.scrollY {
    overflow-y: scroll;
}

.search-bar {
    border: none !important;
    letter-spacing: 0.2;
    background-color: none !important;
    border-top-right-radius: 20;
    border-bottom-right-radius: 20;
    width: 100%;
}

.search-bar .form-control {
    --input-bg: var(--secondary-color-lighter);
    width: 100%;
    border-color: transparent;
    border-radius: 20px;
}

.input-icons {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 20px;
    /* background-color: #005d86; */
    /* width: 400px; */
    height: 36px;
    align-items: center;
}

.input-icons input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 30px;
    padding-left: 5px !important;
}

.fixTableHead thead th {
    background: #fff;
    position: sticky;
    top: -1px;
    font-weight: 500;
    color: #000;
}


.fixTableHead {
    overflow-y: auto;
    height: 70vh;
    scrollbar-width: 0 !important;
    --webkit-scrollbar-width: 0px !important;
}

.fixTableHead::-webkit-scrollbar {
    display: none;
}

.td-min-height {
    min-height: 64px;
}

.form-check-input:checked {
    background-color: var(--color1);
    border-color: var(--color1);
}

.form-check-input[type="checkbox"]:indeterminate {
    background-color: #878787 !important;
    border-color: #878787 !important;
}

.scroller {
    overflow-x: scroll;
    float: left;
}

.sticky-thc thead tr th {
    position: sticky;
    top: 150px;
}

.tle-fixed {
    overflow-y: scroll;
    overflow-x: scroll;
    max-height: 80vh;
    scrollbar-width: 0px !important;
    position: relative;
}

.tle-fixed table {
    border-collapse: separate;
    border-spacing: 0;
}

.tle-fixed table th {
    /* Apply both top and bottom borders to the <th> */
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.tle-fixed table td {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.tle-fixed table .td-prod,
.tle-fixed table .th-prod {
    position: sticky !important;
    left: 10px !important;
    z-index: 3 !important;
    background-color: #fff !important;
    /* background-color: #fff !important; */
}



.tle-fixed1 {
    overflow-y: scroll;
    overflow-x: scroll;
    max-height: 100vh;
    scrollbar-width: 0px !important;
    position: relative;
}

.tle-fixed1 table {
    border-collapse: separate;
    border-spacing: 0;
}

.tle-fixed1 table th {
    /* Apply both top and bottom borders to the <th> */
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.tle-fixed1 table td {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.tle-fixed1 table .td-prod,
.tle-fixed1 table .th-prod {
    position: sticky !important;
    left: 10px !important;
    z-index: 3 !important;
    background-color: #fff !important;
    /* background-color: #fff !important; */
}

/* .sticky-left-fixed{
    table-layout: fixed;
    position: sticky !important;
    left: 0 !important;
    z-index: 9999 !important;
    background-color: #fff !important;
    width: 100px;
}

.sticky-left-fixed-next {
    position:absolute;
    left:100px; 
    width:100px;
} */

.table-responsive {
    scrollbar-width: none !important;
}

.sticky-table-head {
    position: sticky;
    top: -2px;
    background-color: #fff !important;
    z-index: 5 !important;
}


.number {
    background-color: #8f8f8f;
    padding: 1px 10px 1px 1px;
    border-radius: 20px;
    width: fit-content;
}

.number-radius {
    border-radius: 50%;
    background-color: #fff;
    width: 20px;
    height: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 12px;
}

.user_img {
    width: 100%;
    height: 120px;
    object-fit: cover;
}

.user_img_radius {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.user-dropdown .dropdown-menu-start {
    right: 15px !important;
    width: 75% !important;
}

.overflowX {
    overflow-x: scroll;
    scrollbar-width: none;
}


.btn .badge {
    padding: 4px 6px 3px;
    top: unset !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.drawer-fixed {
    position: fixed;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    padding: 12px 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.hr-drawer {
    width: 10%;
    background-color: #4d4d4d;
    height: 3px !important;
    opacity: 1 !important;
    margin: 0.8rem 0 !important;
    border-radius: 20px !important;
}

.radius-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.ReactSwipeableBottomSheet--open {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    scrollbar-width: none !important;
}


.prods-card .MuiAccordionSummary-root,
.brand .MuiAccordionSummary-root {
    padding: 0px !important;
}

.prods-card .MuiAccordionDetails-root,
.brand .MuiAccordionDetails-root {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.img-prods {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    object-fit: cover;

}

.number-select.form-select {
    background-position: right 0.4rem center !important;
    background-size: 10px !important;
    font-size: 13px !important;
}

.addimg-prods {
    height: 80px;
    width: 100%;
    border-radius: 6px;
    object-fit: contain;
}

.prod-img {
    width: 100%;
    height: 70px;
    border-radius: 6px;
    object-fit: cover;
}

.prod-group-img {
    width: 100%;
    height: 60px;
    border-radius: 6px;
    object-fit: cover;
    border: 1px solid #dbdbdb;
}

.img-pos {
    position: absolute;
    float: left;
    margin-top: -20px;
    margin-left: 10px;
    z-index: 2;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}

.img-pos-card {
    position: relative;
    margin-bottom: 30px;
    /* padding-top: 20px !important; */
}

.rct-node-icon {
    color: #717171 !important;
}

.fa-check-square::before {
    color: #663fc3 !important;
}

/* .fa-minus-square::before {
    color: #663fc3 !important;
} */

.fa-square::before {
    color: #ccc;
}

.rct-title {
    font-size: 14px;
    color: #444;
}

.rct-collapse.rct-collapse-btn {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

}

.rct-text {
    margin-bottom: 6px;
}

.rct-checkbox {
    padding: 6px 9px !important;
    margin-left: 5px;
    cursor: pointer;
}

.css-b62m3t-container {
    width: 100% !important;
}

.folded-ribbon-cyan {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 10px 13px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: darkcyan;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 43px
}

.folded-ribbon-gray {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 10px 13px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: lightgray;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 10px
}

.folded-ribbon-prime {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 10px 13px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: var(--color1);
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 43px
}

.folded-ribbon-orange {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 10px 13px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: darkorange;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 43px
}

.folded-ribbon-violet {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 10px 13px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: #cecfff;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 43px
}

.folded-ribbon-green {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 3px 10px 14px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: #9ee0b3;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 43px
}

.folded-ribbon-red {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 10px 13px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: #ffd2a9;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 43px
}

.folded-ribbon-goldenrod {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 68px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 10px 13px calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: goldenrod;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    top: 43px;
}

.h-37px {
    height: 37px;
}

.h-300 {
    height: 300px;
}

.hover-row:hover {
    background-color: #f5f5f5;
}

.type-img {
    width: 14px;
    height: 14px;
}

.type-img-big {
    width: 18px;
    height: 18px;
}

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.trigger-menu-wrapper {
    position: fixed;
    top: 50px;
    /* left: 0;
    right: 0; */
    display: flex;
    justify-content: center;
    padding: 20px;
    z-index: 11;
    background: var(--lightpurple);
    transition: transform 0.4s;
}

.page-header .trigger-menu {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    color: var(--white);
    letter-spacing: .2em;
}

.page-header .trigger-menu svg {
    fill: var(--white);
    margin-right: 8px;
    transition: transform 0.3s;
}

.page-header .menu {
    position: fixed;
    top: 50px;
    /* left: 0;
    right: 0; */
    bottom: 0;
    display: none;
    text-align: center;
    padding: 15vh 0 5vh;
    overflow: auto;
    z-index: 1;
    background: var(--lightpurple);
}

.page-header .menu a {
    font-size: 3rem;
}

.page-header .sub-menu a {
    font-size: 1.5rem;
}

.lottie-wrapper {
    position: fixed;
    bottom: 50px;
    right: 25px;
    z-index: 1;
    padding: 5px;
    border-radius: 5px;
}

.page-main section {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.page-main section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
}


.page-header .trigger-menu svg {
    transition: transform 0.3s;
}

.menu-open {
    overflow: hidden;
}

.menu-open .page-header .menu {
    display: block;
}

.menu-open .page-header svg {
    transform: rotate(45deg);
}

.trigger-menu-wrapper {
    transition: transform 0.4s;
}

.scroll-down .trigger-menu-wrapper {
    transform: translate3d(0, -100%, 0);
}

.scroll-down .lottie-wrapper {
    background: var(--darkgray);
}

.scroll-up .trigger-menu-wrapper {
    transform: none;
}

.scroll-up:not(.menu-open) .trigger-menu-wrapper {
    background: var(--lightpurple);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}

.mt-8rem {
    margin-top: 8rem;
}

.pack .form-select.pack-width {
    width: 30% !important;
    padding-right: 20px;
    flex: none !important;
    cursor: pointer;
}

.pack .form-control.w-70 {
    width: 70% !important;
    flex: none !important;
}

.empty-img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.delivery {
    width: 25px;
    height: 25px;
}

.border-green {
    border: 1px solid green;
}

.prod-outlet-img {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    object-fit: contain;
    /* background: #ecedf1; */
}

.prod-inven-img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    object-fit: contain;
    /* background: #dbdbdb; */
}

.order-alice .alice-carousel__stage-item {
    width: fit-content !important;
}

.order-alice .alice-carousel__stage-item .tab-view,
.order-alice .alice-carousel__stage-item .active-tab-view {
    padding: 6px 18px !important;
}

.w-fit {
    width: fit-content !important;
}

.custom-table-row {
    display: flex;
    width: 100%;
}

.flex-basis-20 {
    width: 20%;
}

.flex-basis-10 {
    width: 10%;
}

.flex-basis-15 {
    width: 15%;
}

.flex-basis-5 {
    width: 5%;
}

.mobile-column-name {
    padding-bottom: 15px;
    font-weight: 600;
}

.center-wrapper {
    margin-top: 5px;
}

.chan .MuiAccordionSummary-root.Mui-expanded {
    background-color: #ececfb;
}

.th-left {
    position: sticky;
    left: 10px !important;
    z-index: 10;
    position: -webkit-sticky;
}


.th-product-group .th-title {
    color: #000;
    font-weight: 600;
}

.th-product .th-title {
    color: #222;
    font-weight: 400;
    margin-left: 20px;
}

.th-addon-group .th-title {
    color: #444;
    font-weight: 600;
    margin-left: 40px;
}

.th-addon-item .th-title {
    color: #555;
    font-weight: 400;
    margin-left: 60px;
}

.checktable-page th.td-prod {
    width: 40%;
    min-width: 300px;
}

.font-poppins {
    font-family: 'Poppins', sans-serif !important;
}

.font-noto-sans {
    font-family: "Noto Sans", sans-serif !important;
}

.btn-rounded {
    border-radius: 25px;
}

.btn-outline-none {
    outline: none !important;
    box-shadow: none !important;
}

.intro-div .alice-carousel__dots {
    margin-top: 15px;
}

.intro-div .alice-carousel__dots-item:not(.__custom) {
    width: 5px;
    height: 5px;
}

.intro-div .alice-carousel__dots-item:not(.__custom):hover,
.intro-div .alice-carousel__dots-item:not(.__custom).__active {
    width: 15px;
    border-radius: 5px;
    background-color: #0d6efd;
}

.intro-div .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 5px;
}

.new_header {
    z-index: 10;
}


.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}

.border_card {
    border: 1px solid #d0d0d0;
}

.new-order-alert {
    background-color: #008000;
    bottom: 0px;
    left: 0;
    width: 100%;
    position: fixed;
    padding: 16px 10px;
    margin-top: 10px;
    color: #ffff;
    z-index: 99999;
    align-items: center;
    /* justify-content: center; */
    display: flex;
    scroll-behavior: smooth;
    transition: 0.5s;
}

.active-shadow-layout {
    background-color: rgb(0 0 0 / 41%) !important;
    filter: blur(1px);
    -webkit-filter: blur(1px);
}

.bill-details .MuiAccordionSummary-content {
    margin: 0px !important;
}

.bill-details .MuiIconButton-root {
    padding: 3px 12px !important;
}


.bill-details .MuiAccordionSummary-root {
    min-height: 30px !important;
}

.bill-details .MuiAccordionDetails-root {
    padding-bottom: 0px !important;
}

.scrollX {
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: none !important;
    --webkit-scrollbar: none !important;
    --webkit-scrollbar-width: none !important;
}

.scrollX::-webkit-scrollbar,
.sidebar::-webkit-scrollbar {
    display: none !important;
}


.header-tap {
    /* border-bottom: 3px solid var(--color1);
    border-radius: 0 !important; */
    box-shadow: none !important;
    /* color: var(--color1) !important; */
    font-weight: 600;
    color: var(--color1);
    position: relative;
}

.header-tap::before {
    content: '';
    position: absolute;
    bottom: 0;
    /* left: 0; */
    /* right: 0; */
    border-bottom: 3px solid var(--color1);
    height: 0px;
    border-radius: 10px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-lightgray {
    background-color: rgb(237, 237, 237);
}

.dperson-details .MuiAccordionSummary-content {
    margin-top: 0px !important;
}

.dperson-details .MuiIconButton-root {
    padding: 0px 12px 9px;
}

.dperson-details .MuiAccordionSummary-expandIcon.Mui-expanded {
    padding: 9px 12px 0px;

}

.dperson-details .MuiAccordionDetails-root {
    padding-bottom: 5px !important;

}



.lds-dual-ring {
    display: inline-block;
    width: 18px;
    height: 18px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 3px solid gray;
    border-color: gray transparent gray transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.order_detail .MuiAccordionSummary-content {
    margin: 0px 0px 5px !important;
}



.prod-timing-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; */
    padding: 5px;
}

.img-14px {
    width: 14px;
    height: 14px;
}

.order_details_page .card,
.orders_list_page .card {
    min-height: 100%;
}



.bg-lightwhite {
    background-color: #f2f1ff;
}

.shadow-top {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* /////////////////////////////20012024 ////////////////////////*/

.swipeable-drawer-container-footer .cupertino-pane-wrapper {
    z-index: 1331;
}

/** Cupertino Panes Css */
.cupertino-pane-wrapper.swipeable-drawer .pane {
    cursor: default !important;
    padding: 0;
}

.swipeable-drawer-content {
    height: 100%;
}

.swipeable-drawer-content.rounded-corner {
    border-radius: 10px;
}

.cupertino-pane-wrapper .bar {
    height: 3px;
    width: 50px;
    background: #ccc;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.swipeable-drawer-container-footer .cupertino-pane-wrapper {
    z-index: 1331;
}

.btn-1drawer-content {
    padding-top: 6px;
}

.btn-1drawer-bar {
    margin-bottom: 6px;
}

.bottom-45 {
    bottom: 45px !important;
}

.card_accordian_li {
    list-style-type: none !important;
}

.logo {
    width: 25px;
    height: 25px;
    margin: 5px;
}

/* footer bottom sheet start*/

.main-hr-drawer {
    margin: 0 auto;
    /* height: 6px !important; */
    height: 3px !important;
    background: #c0c0c0;
    /* width: 36px; */
    width: 50px;
    border-radius: 4px;
}

.dashboard-app-btns {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-app-btns .dashboard-app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    text-align: center;
    padding: 5px 2px;
    text-decoration: none;
    box-shadow: 0px 1px 2px #d0d0d0;
    border: 1px solid #f1f1f1;
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboard-app-btns .dashboard-app-btn-col {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 3px;
}

.dashboard-app-btns .dashboard-app-btn.food-btn img {
    max-height: 57px;
}

.dashboard-app-btns .dashboard-app-btn img {
    max-height: 40px;
}

.dashboard-app-btns .dashboard-app-btn .dashboard-app-btn-title {
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: none;
    font-family: "Roboto";
    color: #343a40 !important;
}

.beta-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #007bff;
    color: #fff;
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    border-radius: 5px;
}


.btn-menu-back {
    border-radius: 50%;
    height: 35px;
    /* width: 35px; */
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 10px;
    color: #343a40 !important;
    font-size: 12px;
}

.superapp_menu_tabs {
    border-top: 1px solid #f1f1f1;
}

.footer-bottomsheet {
    /* z-index:999 !important; */
    /* position: absolute !important; */
    padding: 5px !important;
}

.footer-back {
    height: 40px !important;
    padding: 5px !important;
    position: fixed;
    bottom: 45px;
    left: 0;
    width: 100%;
    z-index: 1332;
}

/* footer bottom sheet end*/


.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000 !important;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.loader-spinner {
    border: 2px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    animation: spin 1s linear infinite;
}

.map-top-div {
    position: absolute;
    top: 50px;
    left: 20px;
    right: 20px;
}

.map-search-bar {
    position: absolute;
    left: 0;
    right: 0;
    /* margin-left: 10px;
    margin-right: 10px; */
    z-index: 1500;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.w-80per {
    width: 80%;
}

.shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}


.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-text {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0px 6px !important;
    border: none !important;
    background-color: #fff !important;
    /* background-color: transparent !important; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border-radius: 0px !important;
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
    cursor: pointer;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.input-group-text-last {
    margin-top: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 6px !important;
    border: none !important;
    background-color: #fff !important;
    /* background-color: transparent !important; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border-radius: 0px !important;
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
    cursor: pointer;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.map-layer-div {
    position: absolute;
    /* z-index: 1000000; */
    bottom: 100px;
    right: 20px;
}

.form-control {
    display: block;
    width: 100%;
    height: 38px;
    /* margin-top: 4px; */
    height: calc(2.25rem + 3px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    /* background-color: #fff; */
    background-clip: padding-box;
    border-radius: 20px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
    /* border-color: #ccc; */
    /* box-shadow: 0 0 8px rgba(130, 130, 130, 0.25) ; */
    box-shadow: none;
}

/* .input-group .form-control {
    border: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
} */

.input-group .form-control1 {
    border: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.PackageDetailsModal .input-group .form-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

.pac-target-input {
    animation-name: endBrowserAutofill;
    margin-top: 10px;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.radius-4 {
    border-radius: 4px;
}

.w-10per {
    width: 10%;
}

.w-20per {
    width: 20%;
}

.w-30per {
    width: 30%;
}

.w-40per {
    width: 40%;
}

.w-50per {
    width: 50%;
}

.w-60per {
    width: 60%;
}

.w-70per {
    width: 70%;
}

.w-80per {
    width: 80%;
}

.w-90per {
    width: 90%;
}

.w-100per {
    width: 100%;
}

.w-10 {
    width: 16px;
    height: 16px;
}

.w-98 {
    width: 98%;
}

.w-15per {
    width: 15%;
}

.w20 {
    width: 20%;
}

.w-20 {
    width: 20px;
    height: 20px;
}

.wh-30 {
    width: 30px;
    height: 30px;
}

.w-35 {
    width: 35%;
}

.w-fit {
    width: fit-content !important;
}

.product-timing-location {
    border: none !important;
    font-size: 12px;
    color: #3e3e3e;
    font-weight: 600;
}



.lds-dual-ring {
    display: inline-block;
    width: 18px;
    height: 18px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 3px solid var(--color1);
    border-color: var(--color1) transparent var(--color1) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* .scrollX {
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: none !important;
    --webkit-scrollbar: none !important;
    --webkit-scrollbar-width: none !important;
}

.scrollX::-webkit-scrollbar {
    display: none !important;
} */

.border-bottom-gray {
    border-bottom: 3px solid #ebecf0;
}

.logo-div {
    background-color: #ecedf1a3;
    border-radius: 50px;
    padding: 3px;
    position: absolute;
    bottom: 13px;
    /* box-shadow: 0px 9px 133px 6px rgba(148,142,189,1); */

}

.logo-div .logo {
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */

}

.active-tab {
    border-bottom: 1px solid var(--color1);
}

.bg-lightprime {
    background-color: #f3f7ff !important;
}

.bg-lightgray {
    background-color: #ededed;
}

.fw-600 {
    font-weight: 600;
}

input::placeholder,
select::placeholder {
    font-size: 12px;
}

input,
select {
    font-size: 12px !important;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
    border-radius: 20px !important;
}


/* Switch */
.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    width: max-content;
}

/* Input */
.pure-material-switch>input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    right: 10px;
    top: -2px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    /* background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38); */
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch>span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

/* Track */
.pure-material-switch>span::before {
    content: "";
    float: left;
    display: inline-block;
    margin: 5px 0 5px 10px;
    border-radius: 7px;
    width: 36px;
    height: 14px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch>span::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch>input:checked {
    left: 21px;
    background-color: rgb(var(--pure-material-primary-rgb, 0, 87, 255));
}

.pure-material-switch>input:checked+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 0, 87, 255), 0.6);
}

.pure-material-switch>input:checked+span::after {
    background-color: rgb(var(--pure-material-primary-rgb, 0, 87, 255));
    transform: translateX(16px);
}

/* Hover, Focus */
.pure-material-switch:hover>input {
    opacity: 0.04;
}

.pure-material-switch>input:focus {
    opacity: 0.12;
}

.pure-material-switch:hover>input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-switch>input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-switch>input:active+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.pure-material-switch>input:checked:active+span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

/* Disabled */
.pure-material-switch>input:disabled {
    opacity: 0;
}

.pure-material-switch>input:disabled+span {
    color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    opacity: 0.38;
    cursor: default;
}

.pure-material-switch>input:disabled+span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-switch>input:checked:disabled+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.out_of_stock_swipe input[type="radio"] p {
    color: #222;
    text-align: center;
}


.out_of_stock_swipe input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.out_of_stock_swipe input[type="radio"]:checked+p {
    color: var(--color1) !important;
}

.out_of_stock_swipe label {
    border: 1px solid #a2a2a2;
    width: fit-content;
    /* height: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    margin: 0 10px 10px 0;
}

.out_of_stock_swipe input[type="radio"]:checked+label {
    border-color: var(--color1) !important;
}

.out_of_stock_swipe .out_of_stock_swipe_label:has(> input[type="radio"]:checked) {
    border-color: var(--color1) !important;
}

.text-light-gray {
    color: #ccc;
}

.d-inline {
    display: inline;
}

.border-light-gray {
    border-color: #ccc !important;
}

.menu-icon {
    width: 16px !important;
}

.footer_icon {
    width: 35% !important;
}

.w-25px {
    width: 25px;
}

.border-right-2px {
    border-right: 2px solid #000;
}

.status-round {
    width: 8px;
    height: 8px;
    border-radius: 50px;
}

.border-bottom-light-2px {
    border-bottom: 3px solid #ECEDF1;
}

.fs-8px {
    font-size: 8px;
}

.btn-loader {
    border-top: 0.2em solid currentcolor;
    border-right: 0.2em solid transparent;
    -webkit-animation: btn-loader 1s linear infinite;
    animation: btn-loader 1s linear infinite;
    border-radius: 100%;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
}

@-webkit-keyframes btn-loader {
    to {
        transform: rotate(360deg);
    }
}

@keyframes btn-loader {
    to {
        transform: rotate(360deg);
    }
}

.footer_button {
    width: 100%;
}

.lowercase {
    text-transform: lowercase;
}

.action_menu_img {
    width: 18px;
    height: 18px;
}

.reminder-input-box {
    position: absolute;
    top: 10px;
    left: 10px;
}

.w-50px {
    width: 50px;
    height: 50px;
}

.w-30px {
    width: 30px;
    height: 30px;
}

.form-check-input {
    border: 1px solid rgb(0 0 0 / 48%) !important;
}

.availble-time-css {
    background-color: peachpuff;
    padding: 4px 13px !important;
    width: fit-content;
    margin-bottom: 7px;
    border-radius: 13px;
    color: #000 !important;
    font-size: 9px !important;
    font-weight: 600;
}

.leftabsolute {
    position: absolute;
    top: 60px;
    background-color: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    left: -25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.rightabsolute {
    position: absolute;
    top: 60px;
    background-color: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    right: -25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    display: block !important;
    padding: 0px !important;
}

.w-16px {
    width: 16px;
    height: 16px;
}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    font-size: 12px;
    color: #333;
    height: 70px;

}

.white-space-nowrap {
    white-space: nowrap;
}

.badge-1 {
    text-transform: uppercase;
    font-size: 11px;
    padding: 1px 7px 1px 7px;
    border-radius: 4px;
    font-weight: 500;
    background: #0057FF;
    color: #fff;
}

.badge-1.badge-green {
    background: #009026;
}

.badge-1.badge-green-2 {
    background: #8dd202;

}

.badge-1.badge-orange {
    background: #FF6F00;

}

.badge-1.badge-red {
    background: #e20f0f;
}

.badge-1.badge-red-2 {
    background: #ff0000;
}

.badge-1.badge-purple {
    background: #7700fa;
}

.badge-1.badge-pink {
    background: #ff00d0;
}

.badge-1.badge-blue {
    background: #0057FF;
}

.badge-2 {
    text-transform: uppercase;
    font-size: 12px;
    background: #d7f1ff;
    padding: 1px 5px 2px 5px;
    border-radius: 3px;
    font-weight: 500;
    color: #909191;
}

.badge-online {
    display: inline-block;
    background-color: #006800;
    color: #ffffff;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
}

.badge-offline {
    display: inline-block;
    background-color: #989797;
    color: #ffffff;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
}

.mui-accordion-nospace .MuiAccordionSummary-root {
    padding: 0;
    min-height: auto;
}

.mui-accordion-nospace .MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin: 0;
}

.mui-accordion-nospace .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}

.custom-incrementer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 6px;
}

.custom-incrementer .btn {
    font-size: 18px !important;
    padding: 6px 10px;
}

.custom-incrementer .counter-number {
    font-size: 12px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    width: 100%;
    padding: 10px;
}

.btn-reject {
    color: #dc3545;
    border-color: #d1d1d1;
    font-weight: 500 !important;
    font-size: 14px !important;
    width: 100%;
    border-radius: 4px !important;
    padding: 9px;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-accept {
    color: #ffffff !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    width: 100%;
    border-radius: 4px !important;
    padding: 9px;
    background-color: #009026 !important;
    text-transform: uppercase;
    cursor: pointer;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.product-diet {
    width: 16px;
}

.product-diet-details {
    width: calc(100% - 16px);
}

.timeline-1 .timeline-dot {
    width: 20px;
    height: 20px;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.timeline-1 .timeline-dot.active {
    background-color: #0057ff;
}

.timeline-1 .timeline-line-container {
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeline-1 .timeline-line {
    height: 30px;
    width: 3px;
    background-color: #cccccc;
}

.timeline-1 .timeline-line.active {
    background-color: #0057ff;
}

.footertab-btn {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}


/* Lazy Loading Css */
.lazy-load:has(.lazy),
.lazy-img,
.lazy-div {
    --bg: #f1f1fa;
    --glare-bg: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.5), transparent);
    position: relative;
    background-color: var(--bg);
    overflow: hidden;
}

.lazy-load:has(.lazy)::before,
.lazy-img::before,
.lazy-div::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.75s infinite ease-out;
    z-index: 1;
}

@keyframes skeleton-glare {
    to {
        transform: translateX(100%)
    }
}

.lazy-load:has(.lazy)>*,
.lazy-img>*,
.lazy-div>* {
    visibility: hidden;
}


.box-3 {
    background: #ffffff;
    border-radius: 6px;
}

.stat-pic {
    min-height: 76px;
}

.callButton {
    background-color: #ecf1fb;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Sendbird chat css */
.chat-header {
    height: 60px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
}

.chat-header .profile-image {
    max-width: 30px;
    max-height: 30px;
    border-radius: 100%;
    margin-right: 10px;
}

.sendbird-hide-header .sendbird-conversation .sendbird-chat-header {
    display: none;
}

.sendbird-app-container .sendbird-fileviewer {
    top: 60px;
}

.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header__left__avatar,
.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header__left__sender-name {
    display: none !important;
}

.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__filename {
    max-width: 150px;
    font-size: 15px;
}

.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__content {
    height: calc(100% - 172px);
}



.md-checkbox {
    position: relative;
    margin: 1em 0;
    text-align: left;
}

.md-checkbox.md-checkbox-inline {
    display: inline-block;
}

.md-checkbox label {
    cursor: pointer;
    display: inline;
    line-height: 1.25em;
    vertical-align: top;
    clear: both;
    padding-left: 1px;
}

.md-checkbox label:not(:empty) {
    padding-left: 0.75em;
}

.md-checkbox label:before,
.md-checkbox label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
}

.md-checkbox label:before {
    width: 1.25em;
    height: 1.25em;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 0.125em;
    cursor: pointer;
    transition: background 0.3s;
}

.md-checkbox input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: 1.25em;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;
}

.md-checkbox input[type="checkbox"]:checked+label:before {
    background: #007bff;
    border: none;
}

.md-checkbox input[type="checkbox"]:checked+label:after {
    transform: translate(0.25em, 0.3365384615em) rotate(-45deg);
    width: 0.75em;
    height: 0.375em;
    border: 0.125em solid #fff;
    border-top-style: none;
    border-right-style: none;
}

.md-checkbox input[type="checkbox"]:disabled+label:before {
    border-color: rgba(0, 0, 0, 0.26);
}

.md-checkbox input[type="checkbox"]:disabled:checked+label:before {
    background: rgba(0, 0, 0, 0.26);
}

@keyframes rippleRadio {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
    }

    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
    }
}

.md-radio.md-radio-inline {
    display: inline-block;
}

.md-radio input[type="radio"] {
    display: none;
}

.md-radio input[type="radio"]:checked+label:before {
    border-color: #007bff;
    animation: rippleRadio 0.2s linear forwards;
}

.md-radio input[type="radio"]:checked+label:after {
    transform: scale(1);
}

.md-radio label {
    display: inline-block;
    min-height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
}

.md-radio label:before,
.md-radio label:after {
    position: absolute;
    content: "";
    border-radius: 50%;
    transition: all 0.3s ease;
    transition-property: transform, border-color;
}

.md-radio label:before {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(218, 218, 218, 0.8);
}

.md-radio label:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    transform: scale(0);
    background: #007bff;
}

.radio-border {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
}

.radio-border .md-radio label {
    width: 100%;
}

.br-0-imp {
    border-radius: 0 !important;
}

.mobile-input input {
    border-radius: 0px !important;
}

.mobile-input input:nth-child(1) {
    max-width: 55px;
    text-align: center;
}

.cursor:hover {
    background-color: #f0f0f0;
    /* Light gray background on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Light shadow on hover */
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Smooth transition */
}

.cursor:focus {
    background-color: #e0e0e0;
    /* Slightly darker gray background on focus */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Slightly stronger shadow on focus */
    outline: none;
    /* Remove default outline */
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Smooth transition */
}

/* 17/07/2024 */

.bottom_data .center {
    bottom: 60px;
    position: fixed;
    width: 100%;
    /* scroll-behavior: smooth; */
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
    z-index: 5;
}

.bottom_data h6,
.bottom_data_viewcart h6 {
    color: #fff !important;
}

.bottom_data .bg-dark,
.bottom_data_viewcart .bg-dark {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.8) !important;
    cursor: pointer;
    z-index: 2;
}

.bottom_data .footer-hidden-center {
    bottom: 60px;
    position: fixed;
    width: 100%;
    scroll-behavior: smooth;
    -webkit-transition: 0.95s;
    -moz-transition: 0.95s;
    transition: 0.95s;
    z-index: 5;
    align-items: center;
    justify-content: center;
    display: flex;
    left: 0;
}

.menu-center {
    display: flex;
    flex-direction: row;
}

.menu-center:before,
.menu-center:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #333;
    margin: auto;
}

.MuiMenu-paper {
    /* width: 90%;
    padding: 8px;
    left: 16px !important;
    bottom: 50px !important;
    min-height: auto !important;
    overflow-y: scroll;
    max-height: 90vh !important;
    bottom: 20px !important;
    border-radius: 24px !important; */
    max-height: 70% !important;
}

.MuiPopover-root {
    /* background-color: #4848489c !important; */
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
    backdrop-filter: blur(20px);
}


.menu-card {
    padding: 8px;
    background-color: #fff;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.menu-card-new{
    padding: 8px;
    background-color: #fff;
    /* position: absolute; */
    width: 100%;
    top: 0;
    z-index: 5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.menu-close-btn {
    bottom: 60px !important;
    left: 0;
    position: fixed;
    width: 100%;
    transition: none !important;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow-y: hidden;
}

.menu-close-btn .btn {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.8) !important;
    cursor: pointer;
    z-index: 2;
    transition: none !important;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow-y: hidden;
}

.MuiListItem-root.Mui-selected {
    background-color: transparent !important;

}

.MuiListItem-button,
.MuiListItem-root button {
    transition: none !important;
}

.MuiListItem-root.Mui-selected a.active p,
.MuiListItem-root.Mui-selected a.active p :hover {
    background-color: transparent !important;
    color: #00ac0b !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
}

.MuiList-root .MuiListItem-root.Mui-selected {
    list-style: circle !important;
}

.MuiPopover-root a {
    color: #222222;
}

div.MuiPopover-root {
    background-color: #0000004f !important;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: none !important;

}

.MuiMenu-paper {
    bottom: 170px;
    position: fixed !important;
}

.MuiMenuItem-root .flex {
    width: 100%;
}


.near-img,
.near-img-1 {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.near-img-1 {
    height: 110px;
}

.near-img-gray,
.near-img-gray-1 {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    /* filter: grayscale(100%); */
}

.near-img-gray-1 {
    height: 110px;
}

.prod_collection .MuiAccordionDetails-root {
    padding: 8px 6px 16px !important;

}

.w20px {
    width: 20px;
}

.strike {
    text-decoration: line-through;
}

.food-menu-open .MuiPopover-paper {
    width: 100% !important;
}

.scroll60 {
    max-height: 60vh !important;
    overflow-y: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.bottom_data .center {
    bottom: 60px;
    position: fixed;
    width: 100%;
    /* scroll-behavior: smooth; */
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
    z-index: 5;
}

.l-0 {
    left: 0px !important;
}

.bg-lightblue {
    background-color: #f2f6fd;
}

.type-img {
    width: 14px;
    height: 14px;
}

/* .prod_select{
    border: 1px soild #ccc;
    border-radius: 20px;

}

.manage-product-select {
    border: none !important;
    font-size: 12px;
    color: #3e3e3e;
    font-weight: 500;
    padding: 0px 2px 3px;
} */

.select-wrap {
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    padding: 0 5px;
    /* width: 200px; */
    background-color: #fff;
    position: relative;
    height: 55px;
}

.select-wrap label {
    font-size: 11px !important;
    text-transform: uppercase;
    color: #999 !important;
    padding: 0 8px;
    position: absolute;
    top: 6px;
}

.select-wrap select {
    background-color: transparent !important;
    border: 0px;
    height: 70px;
    font-size: 12px !important;
    padding: 0.4rem;
    color: #111111 !important;
}

.prod_collection .MuiAccordionSummary-root.Mui-expanded {
    position: sticky;
    /* top: 122px; */
    top :50px;
    background-color: #fff;
    /* z-index: 1; */
    z-index: 5;

}

.prod_collection .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0px 5px !important;
    padding: 12px 0px !important;
}

#location-pickup-map-with-marker-100 {
    height: calc(100% - 49px) !important;
    position: relative;
}

#location-pickup-map-with-marker-100 ::after {
    width: 22px;
    height: 35px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    /* background: url('https://cdn3.1superapp.com/superapp/markerpin.png'); */
    background-size: 22px 35px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

.map-search-bar {
    position: absolute;
    left: 0;
    right: 0;
    /* margin-left: 10px;
    margin-right: 10px; */
    z-index: 1500;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
}

/* material switch css */


/* Switch */
.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    width: max-content;
}

/* Input */
.pure-material-switch>input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    right: 10px;
    top: -2px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    /* background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38); */
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch>span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

/* Track */
.pure-material-switch>span::before {
    content: "";
    float: left;
    display: inline-block;
    margin: 5px 0 5px 10px;
    border-radius: 7px;
    width: 36px;
    height: 14px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch>span::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch>input:checked {
    left: 21px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-switch>input:checked+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.pure-material-switch>input:checked+span::after {
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    transform: translateX(16px);
}

/* Hover, Focus */
.pure-material-switch:hover>input {
    opacity: 0.04;
}

.pure-material-switch>input:focus {
    opacity: 0.12;
}

.pure-material-switch:hover>input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-switch>input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-switch>input:active+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.pure-material-switch>input:checked:active+span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

/* Disabled */
.pure-material-switch>input:disabled {
    opacity: 0;
}

.pure-material-switch>input:disabled+span {
    color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    opacity: 0.38;
    cursor: default;
}

.pure-material-switch>input:disabled+span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-switch>input:checked:disabled+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.select-label {
    font-size: 13px;
    color: #000000;
    z-index: 1;
    font-family: "Poppins";
    padding-left: 4px !important;
}

input.outside,
.custom-select,
input[type=text].outside, 
input[type=date].outside 
{
    color: #000000;
    width: 100%;
    font-size: 13px;
    height: 44px;
    line-height: normal;
    border: #ddd solid 1px;
    border-radius: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: -1px;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    z-index: 1;
    font-family: "Poppins";
    background-color: #fff;
}

input:focus,
.custom-select:focus {
    outline: 0 !important;
    color: #555 !important;
    border-color: #9e9e9e;
    /* z-index: 2 */
}

/* input:focus~.floating-label-outside input:not(:focus):valid~.floating-label-outside,
.custom-select:focus~.floating-label-outside .custom-select:not(:focus):valid~.floating-label-outside {
    top: 12px;
    left: 16px;
    font-size: 10px;
    opacity: 1;
    font-weight: 400
}

input:focus~.floating-label-outside,
input:valid~.floating-label-outside,
.custom-select:focus~.floating-label-outside,
.custom-select:valid~.floating-label-outside {
    top: -7px;
    font-size: 11px;
    color: #A6A6A6;
    background: #fff;
    padding: 0px 5px;
    font-family: "Poppins-regular";
}

input:focus~.floating-label-outside,
input:not(:focus):valid~.floating-label-outside,
.custom-select:focus~.floating-label-outside,
.custom-select:not(:focus):valid~.floating-label-outside {
    left: 12px
}

.floating-label-outside {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 14px;
    transition: .2s ease all;
    color: #afafaf;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .5px;
    z-index: 3;
    text-transform: capitalize;
    font-family: "Poppins-regular";

} */

.floating-custom-label {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: -7px;
    transition: .2s ease all;
    color: #afafaf;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: .5px;
    z-index: 3;
    text-transform: capitalize;
    font-family: "Poppins-regular";
    background-color: #fff;
    padding: 0px 3px;

}

.input-icon-outside {
    position: absolute;
    top: 18px;
    left: 15px;
    z-index: 3;
    color: #727272;
}


.primary-button {
    background-color: #0057FF;
    color: #fff;
    font-family: "Poppins-semi-bold";
    width: 100%;
    border-radius: 10px;
    padding: 12px 16px;
    text-transform: uppercase;
    border: none;
    font-size: 13px;
}

.disabled-button {
    background-color: #8A8A8A;
    color: #fff;
    font-family: "Poppins-semi-bold";
    width: 100%;
    border-radius: 10px;
    padding: 12px 16px;
    text-transform: uppercase;
    border: none;
    font-size: 13px;
}

.locate-me {
    padding: 12px 6px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
}

input::placeholder,
textarea::placeholder {
    font-size: 12px !important;
    color: #ccc !important;
    letter-spacing: 0.2px;
}

.bg-gray {
    background-color: #D9D9D9;
}

.radius-10 {
    border-radius: 10px !important;
}

.radius-6 {
    border-radius: 6px;
}

.pre-img-size {
    width: 60px;
    height: 50px;
    object-fit: cover;
}

.bg-body-color {
    background-color: #f5f6f8 !important;
}

.over-img-close {
    position: relative;
    top: -18px;
    right: 0px;
    /* background-color: #ffe7ee; */
    width: 20px;
    height: 20px;
    border-radius: 5px;
}


.input-icon-absoulate {
    position: absolute;
    left: 9px;
    z-index: 1;
    margin-top: 16px;
}

.icon-padding {
    padding: 14px 10px 10px 20px !important;
}

.cancel-order-btn-prime {
    background-color: #E0EBFF;
    padding: 10px;
    border-radius: 10px;
    color: #0057FF;
    width: 100%;
    font-family: "Poppins-semi-bold" !important;
    font-size: 14px;
    text-transform: capitalize !important;
}

.btn-bg-fff-text-prime {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    color: #0057FF;
    width: 100%;
    font-family: "Poppins-semi-bold" !important;
    font-size: 14px;
    text-transform: capitalize;
}

.loc-head {
    color: #000;
    font-size: 14px;
    font-family: "Poppins-semi-bold";
    font-weight: 500;
    letter-spacing: 0.1px;
}

.sub-text {
    font-family: "Poppins-regular";
    font-size: 12px;
    color: #777777;
}

.marg {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 36px;
}

.added-prod-img {
    width: 100%;
    height: 90px;
    object-fit: cover;
    border-radius: 6px;
}

.in-stock-switch {
    --button-width: 3.5em;
    --button-height: 2em;
    --toggle-diameter: 1.5em;
    --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
    --toggle-shadow-offset: 10px;
    --toggle-wider: 3em;
    --color-grey: #cccccc;
    --color-green: #36C75B;
}

.slider {
    display: inline-block;
    width: var(--button-width);
    height: var(--button-height);
    background-color: var(--color-grey);
    border-radius: calc(var(--button-height) / 2);
    position: relative;
    transition: 0.3s all ease-in-out;
    bottom: -4px;
}

.slider::after {
    content: "";
    left: 0;
    display: inline-block;
    width: var(--toggle-diameter);
    height: var(--toggle-diameter);
    background-color: #fff;
    border-radius: calc(var(--toggle-diameter) / 2);
    position: absolute;
    top: var(--button-toggle-offset);
    transform: translateX(var(--button-toggle-offset));
    box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
    transition: 0.3s all ease-in-out;
}

.in-stock-switch input[type="checkbox"]:checked+.slider {
    background-color: var(--color-green);
}

.in-stock-switch input[type="checkbox"]:checked+.slider::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
    box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.in-stock-switch input[type="checkbox"] {
    display: none;
}

.in-stock-switch input[type="checkbox"]:active+.slider::after {
    width: var(--toggle-wider);
}

.in-stock-switch input[type="checkbox"]:checked:active+.slider::after {
    transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}

.payment-badge {
    background-color: #2C2C2C;
    color: #fff;
    font-family: "Poppins-regular";
    text-transform: uppercase;
    padding: 2px 4px 0px;
    border-radius: 4px;
    font-size: 11px;
}

.os-icon {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background-color: #0057ff;
}

.border-left-black {
    border-left: 2px solid #000;
}

.orange-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: orange;
    color: #000;
}


.package-over-text {
    position: absolute;
    top: -12px;
    width: fit-content;
    padding: 2px 8px;
    background-color: #fff;
    font-weight: 600;
    font-size: 14px;
    left: 13px;
    margin-bottom: 0px !important;
    border-radius: 6px;
    letter-spacing: 0.5px;
    color: #000;
    font-family: "Celias";
    z-index: 1;
}


.heading {
    font-size: 14px;
    font-family: "Celias";
    font-weight: 600;
    color: #000;
}

fieldset {
    position: relative;
    border: 1px solid #e4e3e3;
    border-radius: 3px;
    margin-top: 5px;
}

legend {
    position: absolute;
    top: -12px;
    width: auto;
    padding: 0 5px;
    background-color: #fff;
    font-weight: 500;
    font-size: 14px;
    right: 13px;
    margin-bottom: 0px !important;
}

.legendLeft {
    position: absolute;
    top: -10px;
    width: fit-content;
    padding: 2px 8px;
    background-color: #fff;
    font-weight: 500;
    font-size: 14px;
    left: 13px;
    margin-bottom: 0px !important;
    border-radius: 6px;
    letter-spacing: 0.3px;
}


.custom-align-top {
    justify-content: flex-start;
    align-items: baseline;
}

.fs-13 {
    font-size: 13px;
}



.order-track {
    margin-top: 5px;
    padding: 0 1rem;
    padding-top: 0px;
    border-top: 1px solid #e7e7e7;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
}

.order-track-step {
    display: flex;
    height: 7rem;
}

.order-track-step:last-child {
    overflow: hidden;
    height: 7rem;
}

.order-track-step:last-child .order-track-status span:last-of-type {
    display: none;
}

.order-track-status {
    margin-right: 1rem;
    position: relative;
}

.order-track-status-dot {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #ddd;
}

.order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #ddd;
}

.order-track-status-dot.completed {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #019901;
}

.order-track-status-line.completed {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #019901 !important;
}

.order-track-status-dot.current {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #019901;
    animation: 1500ms linear infinite pulse;
    box-shadow: 0 0 18px #00ff22;
}

.order-track-text-stat {
    font-weight: 500;
    margin-bottom: 3px;
}

.order-track-text-sub {
    font-size: 1rem;
    font-weight: 300;
}

.order-track {
    transition: all .3s height 0.3s;
    transform-origin: top center;
}

.od-track-step {
    display: flex;
    /* height: 6rem; */
}

.od-track-step:last-child {
    overflow: hidden;
    /* height: 7rem; */
}

.od-track-step:last-child .od-track-status span:last-of-type {
    display: none;
}

.od-track-step-timeline {
    display: flex;
    height: 4rem !important;
}

.od-track-step-timeline:last-child {
    overflow: hidden;
    /* height: 7rem; */
}


.odsettle {
    display: flex;
    width: 100%;
}

.odsettle .order-settle-status {
    width: 49%;
}

.order-settle-status-dot {
    display: block;
    width: 20px;
    height: 18px;
    border-radius: 50%;
    background: #ddd;
}

.order-settle-status-line {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 3px;
    background: #ddd;
    margin-top: 7px;
}

.order-settle-status-dot.completed, .order-settle-status-line.completed {
    background: #019901 !important;
}


.odsette .order-settle-status.w-auto{
    width: auto !important;
}

.order-details .MuiAccordionSummary-root {
    min-height: auto !important;
}

.order-details .MuiAccordionSummary-content {
    margin: 0px !important;
}

.order-details .MuiAccordionDetails-root {
    padding: 0px !important;
}

.filter-invert {
    filter: invert(1);
}

.flip-icon {
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}





input:focus~.floating-label-outside input:not(:focus):valid~.floating-label-outside {
    top: 12px;
    left: 16px;
    font-size: 10px;
    opacity: 1;
    font-weight: 400
}

input:focus~.floating-label-outside,
input:valid~.floating-label-outside {
    top: -6px;
    font-size: 11px;
    color: #A6A6A6;
    background: #fff;
    padding: 0px 5px;
    font-family: "Poppins-light";
    width: auto;
    height: 20px;
    line-height: 11px;
}

input:focus~.floating-label-outside,
input:not(:focus):valid~.floating-label-outside {
    left: 11px
}

.floating-label-outside {
    position: absolute;
    pointer-events: none;
    left: 13px;
    bottom: 36px;
    transition: .2s ease all;
    color: #afafaf;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .5px;
    z-index: 3;
    text-transform: capitalize;
    font-family: "Poppins-light";
    background-color: #fff;
    padding: 0px 3px;
}

.input-icon-outside {
    position: absolute;
    top: 18px;
    left: 9px;
    z-index: 3;
    color: #727272;
}

input[type=text].outside.border-none{
    border: none !important;
}

.floating-label-outside.l-7px {
    left: 6px !important;

}

.floating-label-outside.custom-s {
    padding: 0 0 0 6px !important;
    line-height: 11px;
}

.w-200px {
    width: 200px;
}

.border-right-h-auto {
    border-right: 1px solid #666;
    height: 30px;
}

.radius-none {
    border-radius: 0 !important;
}

.fs-13px {
    font-size: 13px !important;
}

.bg-none {
    background: none !important;
}

/* Hide number input arrows for Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}



  .dropdown-search {
    width: calc(100% - 16px);
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    transition: border 0.3s ease, box-shadow 0.3s ease;
  }

  .modal-footer-new {
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    padding: 5px;
  }

  .bg-blue-500 {
    background-color: #3b82f6; /* Tailwind's blue-500 color */
  }

  .bg-gray-200 {
    background-color: #e5e7eb; /* Light gray for unselected */
  }